import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiStudentFill } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaFileUpload } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { BiLogOut } from "react-icons/bi";
import { CiUser } from "react-icons/ci";
import { MdContactSupport } from "react-icons/md";
import { Auth, userId } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/userSlice";
import { AdminProfileApi, EducatorProfileApi, LearnerProfileApi } from "../../webServices/profileApis";


const Dashboard = ({ children }) => {
  const user = useSelector((store) => store.User.value)
  const dispatch = useDispatch()
  const location = useLocation().pathname
  const navigate = useNavigate()
  const id = userId()

  const openNav = useCallback(() => {
    const sidebar = document.querySelector("aside");
    const maxSidebar = document.querySelector(".max");
    const miniSidebar = document.querySelector(".mini");
    const maxToolbar = document.querySelector(".max-toolbar");
    const logo = document.querySelector(".logo");

    if (sidebar.classList.contains("-translate-x-48")) {
      // max sidebar
      sidebar.classList.remove("-translate-x-48");
      sidebar.classList.add("translate-x-none");
      maxSidebar.classList.remove("hidden");
      maxSidebar.classList.add("flex");
      miniSidebar.classList.remove("flex");
      miniSidebar.classList.add("hidden");
      maxToolbar.classList.add("translate-x-0");
      maxToolbar.classList.remove("translate-x-24", "scale-x-0");
      logo.classList.remove("ml-12");

    } else {
      // mini sidebar
      sidebar.classList.add("-translate-x-48");
      sidebar.classList.remove("translate-x-none");
      maxSidebar.classList.add("hidden");
      maxSidebar.classList.remove("flex");
      miniSidebar.classList.add("flex");
      miniSidebar.classList.remove("hidden");
      maxToolbar.classList.add("translate-x-24", "scale-x-0");
      maxToolbar.classList.remove("translate-x-0");
      logo.classList.add("ml-12");

    }
  }, [])

  const LogOut = () => {
    localStorage.clear()
    navigate('/login')
    window.location.reload()
  }

  const getDetails = useCallback(async () => {
    if (Auth() === "admin") {
      let data = await AdminProfileApi()
      if (data) {
        dispatch(addUser(data))
      }
    } else if (Auth() === "educator") {
      let data = await EducatorProfileApi(id)
      if (data) {
        dispatch(addUser(data))
      }
    } else if (Auth() === "learner") {
      let data = await LearnerProfileApi(id)
      if (data) {
        dispatch(addUser(data))
      }
    }
  }, [id, dispatch])

  useEffect(() => {
    getDetails()
  }, [getDetails])

  return (
    <>
      <div className="fixed w-full z-30 flex bg-white dark:bg-[#0F172A] border-b-2 items-center justify-center px-10">
        <div className="logo ml-12 dark:text-white  transform ease-in-out duration-500 flex-none h-full flex items-center justify-center">
        </div>
        {/* SPACER */}
        <div className="grow h-full flex items-center justify-center" />
        <div className="flex-none h-full text-center flex items-center justify-center">
          <div className="flex space-x-3 items-center px-3">
            <div className="flex-none flex justify-center" >
              <div onClick={() => { navigate("/dashboard/profile") }} className="hover:border-2 flex justify-center items-center p-3 gap-3 hover:cursor-pointer hover:rounded-md">
                <div className="flex">
                  {user && user.image ? <img
                    src={user && user.image}
                    alt="profile"
                    className="shadow rounded-full object-cover h-10"
                  /> :
                    <CiUser fontSize={40} className="rounded-full p-2 border-1 border-[#120365]" />}
                </div>
                <div className="hidden md:block text-sm md:text-md text-black dark:text-white">
                  {user && user.fullname}
                </div>
              </div>
            </div>
            <div className="hidden md:block hover:cursor-pointer font-bold md:text-md text-black dark:text-white hover:border-2 p-3 hover:rounded-md">
              <div onClick={LogOut}>Logout</div>
            </div>
          </div>
        </div>
      </div>

      {/* ==============================sideBar section=================================== */}
      <aside className="w-60 -translate-x-48 fixed transition transform ease-in-out duration-1000 z-50 flex h-screen bg-[#1E293B] ">
        {/* open sidebar button */}
        <div className="max-toolbar translate-x-24 scale-x-0 w-full -right-6 transition transform ease-in duration-300 flex items-center justify-between border-4 border-white dark:border-[#0F172A] bg-[#1E293B]  absolute top-2 rounded-full h-12">
          <div className="flex pl-4 items-center space-x-2 ">
            <div>
              <div
                // onClick={setDark('dark')}
                className="moon text-white hover:text-blue-500 dark:hover:text-[#38BDF8]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="{3}"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                  />
                </svg>
              </div>
              <div
                // onClick={setDark('light')}
                className="sun hidden text-white hover:text-blue-500 dark:hover:text-[#38BDF8]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="text-white hover:text-blue-500 dark:hover:text-[#38BDF8]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="{3}"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </div>
          </div>
          <Link to="/dashboard"><div onClick={openNav} className="flex items-center space-x-3 group text-sm bg-gradient-to-r dark:from-cyan-500 dark:to-blue-500 from-indigo-500 via-purple-500 to-purple-500  pl-10 pr-2 py-1 rounded-full text-white  ">
            <div className="transform ease-in-out duration-300 mr-12">
              Dashboard
            </div>
          </div></Link>
        </div>
        <div
          onClick={openNav}
          className="-right-6 transition transform ease-in-out duration-500 flex border-4 border-white dark:border-[#0F172A] bg-[#1E293B] dark:hover:bg-blue-500 hover:bg-purple-500 absolute top-2 p-3 rounded-full text-white hover:rotate-45"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{3}"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
            />
          </svg>
        </div>
        {/* MAX SIDEBAR*/}
        <div className="max hidden text-white mt-20 flex-col space-y-2 w-full h-[calc(100vh)]">
          {Auth() === "admin" && <Link to="/dashboard/learner"><div onClick={openNav} className={location.includes("learner") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <PiStudentFill />
            <div>Learner</div>
          </div>
          </Link>}
          {Auth() === "admin" && <Link to="/dashboard/educator"> <div onClick={openNav} className={location.includes("educator") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <GiTeacher />
            <div>Educator</div>
          </div>
          </Link>}
          {Auth() === "admin" && <Link to="/dashboard/enquries"> <div onClick={openNav} className={location.includes("enquries") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <MdContactSupport />
            <div>Enquries</div>
          </div>
          </Link>}
          <Link to="/dashboard/profile"> <div onClick={openNav} className={location.includes("profile") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <ImProfile />
            <div>Profile</div>
          </div>
          </Link>
          <Link to="/dashboard/setting"><div onClick={openNav} className={location.includes("setting") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <RiUserSettingsFill />
            <div>Setting</div>
          </div>
          </Link>
          {Auth() === "admin" && <Link to="/dashboard/trash"><div onClick={openNav} className={location.includes("trash") ? "hover:ml-4 w-full text-black hover:text-purple-500 dark:hover:text-blue-500 bg-[#8cb4f3] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3" : "hover:ml-4 w-full text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3"}>
            <FaRegTrashCan />
            <div>Trash</div>
          </div>
          </Link>}
          <div onClick={LogOut} className="hover:ml-4 w-full hover:cursor-pointer text-white hover:text-purple-500 dark:hover:text-blue-500 bg-[#1E293B] p-2 pl-8 rounded-full transform ease-in-out duration-300 flex flex-row items-center space-x-3">
            <BiLogOut />
            <div>Logout</div>
          </div>
        </div>
        {/* MINI SIDEBAR*/}
        <div className="mini mt-20 flex flex-col space-y-2 w-full h-[calc(100vh)] mx-auto">
          {Auth() === "admin" && <Link to="/dashboard/learner"><div className={location.includes("learner") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <PiStudentFill />
          </div>
          </Link>}
          {Auth() === "admin" && <Link to="/dashboard/educator"><div className={location.includes("educator") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <GiTeacher />
          </div>
          </Link>}
          {Auth() === "admin" && <Link to="/dashboard/enquries"><div className={location.includes("enquries") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <MdContactSupport />
          </div>
          </Link>}
          <Link to="/dashboard/profile"><div className={location.includes("profile") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <ImProfile />
          </div>
          </Link>
          <Link to="/dashboard/setting"><div className={location.includes("setting") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <RiUserSettingsFill />
          </div>
          </Link>
          {Auth() === "admin" && <Link to="/dashboard/trash"><div className={location.includes("trash") ? "hover:ml-4 justify-end pr-5 text-black hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#8cb4f3] p-2 rounded-full transform ease-in-out duration-300 flex " : "hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex "}>
            <FaRegTrashCan />
          </div>
          </Link>}
          <div onClick={LogOut} className="hover:ml-4 hover:cursor-pointer justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-[#1E293B] p-3 rounded-full transform ease-in-out duration-300 flex">
            <BiLogOut />
          </div>
        </div>
      </aside>
      {/* ==============================sideBar section end =================================== */}
      {/* {/==================================== CONTENT====================================================} */}
      <main>
        {children}
      </main>
    </>
  );
};

export default Dashboard;