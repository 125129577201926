import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { axiosClient } from '../../webServices/WebURL';
import { webURLs } from '../../webServices/GateWay';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import HeroSection from '../../componets/heroSection';
import Loader from '../../componets/loader';
export default function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false)
    const [btnDisable, setBtnDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error("Please Enter email and password...");
            return;
        }
        try {
            setBtnDisable(true)
            const result = await axiosClient().post(webURLs.LOGIN_USER, {
                email,
                password,
            });
            if (result.data.status) {
                localStorage.setItem("heutx", result.data.data.token)
                localStorage.setItem("userRole", result.data.data.role)
                localStorage.setItem("userId", result.data.data.id)
                setBtnDisable(false)
                toast.success("Logged In Successfully")
                if( result.data.data.role=== "admin")
                {
                    navigate("/dashboard")
                }
                else{
                    navigate("/dashboard/profile")
                }
            }
            else {
                setBtnDisable(false)
                toast.error(result ? result.data.message : "Network Error");
            }
        } catch (error) {
            setBtnDisable(false)
        }

    }

    useEffect(() => {
        localStorage.clear()
    }, [])

    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"Login"} />
                {/* ======= Why Choose Area Start ======= */}
                <section className="services section-startup-bg mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-10 mb-3 p-4">
                                <img src="assets/images/login-img.png" alt='login' />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-10">
                                <div className="contact-form--main mt-4" id="contact-form">
                                    <div className="contact-form p-4">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <span className="section-heading__title_small">Login</span>
                                            </div>
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="col-md-12 mt-4">
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        placeholder="Email"
                                                        value={email}
                                                        className='contact-form__input border border-black'
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-12 flex items-center contact-form__input border border-black">
                                                    <input
                                                        name="password"
                                                        type={visible ? "text" : "password"}
                                                        className="w-full text-[#14133b]"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <div className='cursor-pointer' onClick={() => setVisible(!visible)}>
                                                        {visible ? <FaEye /> : <FaEyeSlash />}
                                                    </div>
                                                </div>
                                                <div className='col-md-12 flex gap-3 mt-4 justify-between'>
                                                    <div><input type="checkbox" /> <span className='font-semibold'>Remember Me</span></div>
                                                    <Link to="/forgot-password" className='font-semibold'><div>Forgot Your Password ?</div></Link>
                                                </div>

                                                <div className="col-md-12 mt-4">
                                                    {btnDisable ? <Loader  className={'contact-form__button'}/> : <button className="contact-form__button">
                                                        Login
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
