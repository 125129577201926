import React from 'react';
import { GiTeacher } from 'react-icons/gi';
import { PiStudentFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { Auth } from '../utils/helper';

export default function Settings() {
  return (
    <>
      <div className='container ps-7 pt-8'>
        <div className='row pt-5 ps-4 justify-around'>
          <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex justify-center gap-2">
              <GiTeacher /><Link to="/dashboard/setting/upload">Upload Files</Link>
            </div>
          </div>
          {Auth() === "educator" && <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex justify-center gap-2">
              <GiTeacher /><Link to="/dashboard/setting/educator">Change Password</Link>
            </div>
          </div>}
          {Auth() === "learner" && <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex justify-center gap-2">
              <PiStudentFill /><Link to="/dashboard/setting/learner">Change Password</Link>
            </div>
          </div>}
          {Auth() === "admin" && <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex justify-center gap-2">
              <GiTeacher /><Link to="/dashboard/setting/admin">Change Password</Link>
            </div>
          </div>}
        </div>
      </div>

    </>
  );
}


