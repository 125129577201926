import React from 'react'
import { Link } from 'react-router-dom'
import HeroSection from '../../componets/heroSection'

export default function TermsConditions() {
  return (
    <>
      <main className="main-wrapper">
        {/* ======= Hero inner Start ======= */}
        <HeroSection sectionName={"Terms of Use"} />
        <section className="careers px-12 pt-4">
          <div className="container">
            <div className="row">
              <div className="section-heading  mb-5">
                <span className="section-heading__title_small">
                  Terms of Use
                </span>
                <ol>
                  <li>
                    <h3 className=" text-xl font-bold mb-1">Acceptance of Terms</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      By accessing or using the services offered by the <strong>Hello Educator </strong>, you agree to be bound by
                      these <strong> Terms of Use </strong>. If you do not agree with any part of these terms, then you may not access or
                      use the service.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1" > Description of Services</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>Hello Educator</strong> offers a digital educational service where educators can provide the best-quality
                      lectors and research guidance to learners at home or virtually in various subjects and fields.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Availability and Scheduling</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      The educator will be provided subject to availability after the deal is confirmed, and a demo
                      class will be scheduled.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Fees and Payment</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a.</strong> Fees will be paid online by learner within a week after completion of demo class as decided
                      by <strong>Hello Educator</strong>.
                      <br />
                      <strong>b.</strong> Remuneration will be paid online by <strong>Hello Educator</strong> to educator by deducting the <strong>20%</strong>&nbsp;
                      service charge at the end of class completion.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Cancellation and Refund Policy</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a.</strong> If class is canceled by learner during educating sessions, then refunds will be provided by
                      deducting the <strong> 20% cancelation charge</strong> at the end of class.
                      <br />
                      <strong>b.</strong> If class is canceled by educator during educating sessions, then the remaining payable
                      remuneration will be provided by deducting the <strong> 20% cancelation charge</strong> at the end of class.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Code of Conduct</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a.</strong> You must respect the intellectual property rights of each other and also use the services in
                      compliance with all applicable laws and regulations.
                      <br />
                      <strong>b.</strong> You must not engage in any activity that disrupts or interferes with the operation of the
                      services.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Privacy Policy</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a. Hello Educator</strong> ensures you that any personal information shared during educating sessions
                      will be kept confidential.
                      <br />
                      <strong>b.</strong> You also ensure that you will not share your personal information during educating sessions.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Intellectual Property</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a.</strong> All content and materials available on the website <Link to="/" className="hover:underline text-cyan-500"> www.helloeducators.in </Link>, including logo,
                      text, graphics, and software are the property of <strong>Hello Educator</strong> and are protected by copyright,
                      trademark, and other intellectual property laws.
                      <br />
                      <strong>b.</strong> You may not use, reproduce, or distribute any content from our website without prior written
                      permission.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Termination of Services</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      If learner tries to contact or approach directly to an educator for education and vice-versa, then,
                      <strong> Hello Educator</strong> reserves the right to terminate or suspend access to the service at any time for
                      any reason, without prior notice.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Liability Waiver</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong>a. Hello Educator</strong> shall not arrange local conveyance and educating facility (i.e., Internet,
                      Laptop, Class Room, Black/White Board, Markers) to conduct the online/offline classes.
                      <br />
                      <strong>b. Hello Educator</strong> shall not be liable for any direct, indirect, incidental, special, or consequential
                      damages arising during the use of the services.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Updates to Terms of Use</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      <strong> Hello Educator</strong> reserves the right to modify or revise these<strong> Terms of Use</strong> at any time.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1">Governing Law and Venue</h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      These<strong> Terms of Use</strong> shall be governed by and construed by the laws of Jurisdiction at Indore.
                    </p>
                  </li>
                  <li>
                    <h3 className="text-xl font-bold mb-1"> Contact Information </h3>
                    <p className="col-lg-12 pb-2 mb-2 text-justify">
                      If you have any questions about this<strong> Terms of Use</strong>, then you are free to contact at
                      <Link to="mailto:info@helloeducators.in" className="hover:underline text-cyan-500"> info@helloeducators.in</Link>.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {/* ======= Contact Area Start ======= */}
        <section
          className="contact contact--agency"
          style={{ backgroundImage: 'url("assets/images/home1/about-right-image-1.jpg")' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="contact__agency">
                  <span className="title text-white">
                    We are here to answer your questions 24/7
                  </span>
                  <h3 className="heading text-white">NEED A CONSULTATION?</h3>
                  <a
                    href="contact.html"
                    className="btn btn--common btn--default btn--primary"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======= Contact Area End ======= */}
      </main>
    </>
  )
}
