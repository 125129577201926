import toast from "react-hot-toast";
import { webURLs } from "./GateWay";
import { axiosClient } from "./WebURL";

export async function uploadEducatorProfile(data) {
    try {
        const response = await axiosClient().put(webURLs.EDUCATOR_UPLOAD_PROFILE, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadLearnerProfile(data) {
    try {
        const response = await axiosClient().put(webURLs.LEARNER_UPLOAD_PROFILE, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadAdminProfile(data) {
    try {
        const response = await axiosClient().put(webURLs.ADMIN_UPLOAD_PROFILE, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadCv(data) {
    try {
        const response = await axiosClient().put(webURLs.EDUCATOR_UPLOAD_CV, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadDegree(data) {
    try {
        const response = await axiosClient().put(webURLs.EDUCATOR_UPLOAD_DEGREE, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadVideo(data) {
    try {
        const response = await axiosClient().put(webURLs.EDUCATOR_UPLOAD_VIDEO, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function uploadLearnerIdCard(data) {
    try {
        const response = await axiosClient().put(webURLs.LEARNER_UPLOAD_ID_CARD, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}