import React, { useCallback, useEffect, useState } from 'react'
import HeroSection from '../../componets/heroSection'
import { allCountApi } from '../../webServices/defaultApis'

export default function EducatorCount() {
    const [count, setCount] = useState()

    const getall = useCallback(async () => {
        let res = await allCountApi()
        if (res) {
            setCount(res)
        }
    },[])

    useEffect(() => {
        getall()
    }, [getall])

    return (
        <>
            <main className="main-wrapper">
                <HeroSection sectionName={"Educator"} />
                <section className="services section-startup-bg mt-6 mb-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 p-4 wow fadeInUp" data-wow-delay=".3s" data-wow-duration=".5s">
                                <div className="counter__item text-center">
                                    <span className="counter__item_count text-black counter-number" data-num="10247">{count?.totalEducators}</span>
                                    <h3 className="counter__item_title text-black">Registered Educators</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
