export function Auth() {
    const user = localStorage.getItem('userRole')
    if (user === "educator") {
        return user;
    }
    if (user === "learner") {
        return user;
    }
    if (user === "admin") {
        return user;
    }
}

export const Colors = ["#e6194B", "#f58231", "#ffe119", "#bfef45",
    "#3cb44b", "#42d4f4", "#4363d8", "#911eb4",
    "#f032e6", "#000075", "#800000", "#9A6324",
    "#808000", "#469990", "#fabed4", "#ffd8b1",
    "#aaffc3", "#911eb4"
]


export function Token() {
    const token = localStorage.getItem('heutx')
    if (token) {
        return token;
    }
}

export function userId() {
    const id = localStorage.getItem('userId')
    if (id) {
        return id;
    }
}

export function DateFormate(date) {
    const formate = new Date(date)
    return `${formate.getDate()}-${formate.getMonth() + 1}-${formate.getFullYear()}`
}

export function passwordValidator(value) {
    let regularExpression = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;
    let valid = regularExpression.test(value);
    return valid;
}

export function Options(data) {
    let arr = data && data.map((item) => (
        { value: item, label: item }))
    return arr
}