import React from 'react'

export default function HowWork() {
    return (
        <>
            {/* ======= Why Choose Area Start ======= */}
            <section className="about about--area mb-3 p-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 mb-30">
                            <div className="section-heading mb-2">
                                <span
                                    className="section-heading__title_small wow fadeInUp animated"
                                    data-wow-delay=".2s"
                                    data-wow-duration=".4s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "0.4s",
                                        animationDelay: "0.2s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    HOW IT WORKS
                                </span>
                                <h2
                                    className="section-heading__title_big wow fadeInUp animated"
                                    data-wow-delay=".3s"
                                    data-wow-duration=".5s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "0.5s",
                                        animationDelay: "0.3s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    Connecting People
                                </h2>
                            </div>
                            <div className="about-content">
                                <p
                                    className="wow fadeInUp animated text-justify mb-3"
                                    data-wow-delay=".5s"
                                    data-wow-duration=".9s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "0.9s",
                                        animationDelay: "0.5s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    Experienced educational professionals, professors, lecturers, teachers, relevant postgraduates,
                                    and academicians—anyone who can demonstrate their ability to teach and deliver best-quality
                                    lectures from home in accordance with the <strong>National Education Policy (NEP)-2020</strong>—can
                                    register as educators on the system.
                                </p>
                                <p className='wow fadeInUp animated text-justify mb-3'
                                    data-wow-delay=".5s"
                                    data-wow-duration=".9s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "0.9s",
                                        animationDelay: "0.5s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    Students, learners, researcher scholars, and anyone else who wants to learn for their career
                                    growth and is seeking for home educators should register as learners on the system.
                                </p>
                                <p className='wow fadeInUp animated text-justify mb-3'
                                    data-wow-delay=".5s"
                                    data-wow-duration=".9s"
                                    style={{
                                        visibility: "visible",
                                        animationDuration: "0.9s",
                                        animationDelay: "0.5s",
                                        animationName: "fadeInUp"
                                    }}
                                >
                                    <strong>Hello Educator </strong>acts as an intermediate link to impart the best-quality education and research guidance by connecting
                                    young learners and experienced professional educators on a single platform.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 flex justify-center mb-3 text-md-center">
                            <div className="col-lg-8 col-md-8 col-sm-10 about-content__image about-content__image_right">
                                <video
                                    className="about-img wow fadeInRight animated"
                                    controls
                                >
                                    <source src='assets/videos/how-it-work-video.mp4' type='video/mp4' />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
