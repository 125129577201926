import React, { useCallback, useEffect, useState } from 'react'
import { Auth } from '../../utils/helper'
import { Navigate } from 'react-router-dom'
import { allEnquiriesApi, deleteEnquiryApi } from '../../webServices/defaultApis'
import toast from 'react-hot-toast'

export default function EnquriesPage() {

    const [enquiries, setEnquiries] = useState([])
    const user = Auth()

    const getAllEnquiries = useCallback(async () => {
        try {
            let res = await allEnquiriesApi()
            if (res) {
                setEnquiries(res)
            }
        } catch (error) {
            console.log(error.message);
        }
    })

    const deleteEnquiries = async (id) => {
        let is = window.confirm("Are You Sure Want To Delete ?")
        if (is) {
            try {
                let res = await deleteEnquiryApi(id)
                if (res) {
                    toast.success(res.message)
                    setEnquiries(enquiries.filter(item => item.id !== id))
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    }


    useEffect(() => {
        if (user === "admin") {
            getAllEnquiries()
        }
    }, [user])

    return (
        <>
            {user === "admin" ? <div className="container-fluid ps-10 pt-20" style={{ paddingLeft: "4rem" }}>
                <div className="row p-0 justify-center">
                    <div className="col-lg-10 col-md-10 col-sm-11 mb-4 border-2">
                        <h2 className='text-center p-2 font-semibold'>Enquiries</h2>
                        <div className="p-3 border-b-2 border-[#030b47]">
                            <div className="bg-white overflow-x-auto">
                                <hr />
                                {enquiries && enquiries.length > 0 ?
                                    <table className="table table-striped table-hover min-w-full table-auto">
                                        <thead>
                                            <tr className='text-center'>
                                                <th className='fs-13 py-3'>Sr No</th>
                                                <th className='fs-13 py-3'>First Name</th>
                                                <th className='fs-13 py-3'>Last Name</th>
                                                <th className='fs-13 py-3'>Email</th>
                                                <th className='fs-13 py-3'>Mobile</th>
                                                <th className='fs-13 py-3'>Message</th>
                                                <th className='fs-13 py-3'>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {enquiries && enquiries.map((item, idx) => (
                                                <tr className='text-center' key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td>{item.firstname}</td>
                                                    <td>{item.lastname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.message}</td>
                                                    <td>
                                                        <div className='grid justify-center gap-2'>
                                                            <button className='btn btn-outline-danger ps-2 pe-2 pt-0 pb-0' onClick={() => { deleteEnquiries(item.id) }}>Delete</button>
                                                             <a className='btn btn-outline-info ps-2 pe-2 pt-0 pb-0' href="http://webmail.helloeducators.in">Reply</a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> : <h2 className='mt-2 mb-2 text-center'>No Enquiry</h2>}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/dashboard" />}
        </>
    )
}
