import React, { useState } from 'react'
import { passwordValidator, userId } from '../../utils/helper'
import { Navigate, useNavigate } from 'react-router-dom'
import HeroSection from '../../componets/heroSection'
import toast from 'react-hot-toast'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'
import { SendOtpApi, updatePassword, VerifyOtpApi } from '../../webServices/forgotpassApi'
import Loader from '../../componets/loader'

export default function ForgotPasswordPage() {
    const navigate = useNavigate()
    const user = userId()
    const [step1Ui, setStep1Ui] = useState(true)
    const [step2Ui, setStep2Ui] = useState(false)
    const [step3Ui, setStep3Ui] = useState(false)
    const [sendOtpRes, setSendOtpRes] = useState()
    const [loading, setLoading] = useState(false)
    const [VerifyRes, setVerifyRes] = useState()
    const [newpassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [passwordError, setPasswordError] = useState()
    const [visible, setVisible] = useState(false)


    function ValidatePass(value) {
        if (passwordValidator(value)) {
            setNewPassword(value)
            setPasswordError(false)
        } else {
            setNewPassword(false)
            setPasswordError("password should be contains atleast One character ,One digit and One Spacial character,minimum password length is 6")
        }
    }

    async function ChangePassword(e) {
        setLoading(true)
        e.preventDefault()
        if (newpassword === false) {
            toast.error("enter valid Password")
            setLoading(false)
            return;
        }
        if (newpassword !== confirmPassword) {
            toast.error("Password is Not Match")
            setLoading(false)
            return;
        }

        let res = await updatePassword(VerifyRes.token, { password: newpassword })
        if (res) {
            toast.success(res.message)
            setLoading(false)
            setStep1Ui(true)
            setStep2Ui(false)
            setStep3Ui(false)
            navigate("/login")
        } else {
            setLoading(false)
        }

    }

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault()
        let formDa = new FormData(e.target);
        const obj = Object.fromEntries(formDa)
        let res = await SendOtpApi(obj)
        if (res) {
            toast.success(res.message)
            setLoading(false)
            setSendOtpRes(res.data)
            setStep1Ui(false)
            setStep2Ui(true)
        } else {
            setLoading(false)
        }
    }

    async function handleVerify(e) {
        setLoading(true)
        e.preventDefault()
        let formDa = new FormData(e.target);
        const obj = Object.fromEntries(formDa)
        let res = await VerifyOtpApi(sendOtpRes.otp, obj)
        if (res) {
            toast.success(res.message)
            setLoading(false)
            setStep2Ui(false)
            setStep3Ui(true)
            setVerifyRes(res.data)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            {!user ? <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"Forgot Password"} />
                {/* ======= Why Choose Area Start ======= */}
                <section className="services section-startup-bg">
                    <div className="container">
                        <div className="row justify-center">
                            {step1Ui ? <div className="col-lg-5 mb-5">
                                <div className="contact-form--main mt-4" id="contact-form">
                                    <div className="contact-form p-4">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <span className="section-heading__title_small">Forgot Password</span>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="col-md-12 mt-4">
                                                    <input
                                                        name="email_id"
                                                        type="email"
                                                        placeholder="Enter email Id"
                                                        className='contact-form__input border border-black'
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    {loading ? <Loader className={'contact-form__button'} /> : <button className="contact-form__button" >
                                                        Submit
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <button className='mb-3 ms-3 text-primary' onClick={() => { navigate("/login") }}>Back</button>
                                </div>
                            </div> : null}
                            {step2Ui ?
                                <div className="col-lg-5 mb-5">
                                    <div className="contact-form--main mt-4" id="contact-form">
                                        <div className="contact-form p-4">
                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <span className="section-heading__title_small">One Time Password</span>
                                                </div>
                                                <form onSubmit={handleVerify}>
                                                    <div className="col-md-12 mt-4">
                                                        <input
                                                            name="otp"
                                                            type="Tel"
                                                            placeholder="Enter OTP"
                                                            className='contact-form__input border border-black'
                                                            minLength={6}
                                                            maxLength={6}
                                                            pattern='\d{6}'
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mt-4">
                                                        {loading ? <Loader className={'contact-form__button'} /> : <button className="contact-form__button" >
                                                            Verify OTP
                                                        </button>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <button className='mb-3 ms-3 text-primary' onClick={() => { window.location.reload() }}>Back</button>
                                    </div>
                                </div> : null}
                            {step3Ui ? <div className="col-lg-5 mb-5">
                                <div className="contact-form--main mt-4" id="contact-form">
                                    <div className="contact-form p-4">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <span className="section-heading__title_small">Reset Password</span>
                                            </div>
                                            <form onSubmit={ChangePassword}>
                                                <div className="col-12 mt-4">
                                                    <div className="col-md-12 col-md-12 col-lg-12 col-sm-12 flex items-center contact-form__input border border-black">
                                                        <input
                                                            name="password"
                                                            type={visible ? "text" : "password"}
                                                            className="w-full text-[#14133b]"
                                                            placeholder="New Password"
                                                            onChange={(e) => { ValidatePass(e.target.value) }}
                                                            required
                                                        />
                                                        <div className='cursor-pointer' onClick={() => setVisible(!visible)}>
                                                            {visible ? <FaEye /> : <FaEyeSlash />}
                                                        </div>
                                                    </div>
                                                    {passwordError ? <label htmlFor="" className='text-red-600'>{passwordError}</label> : null}
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div className="col-md-12 col-md-12 col-lg-12 col-sm-12">
                                                        <input
                                                            name="confirmPassword"
                                                            type="password"
                                                            className="w-full text-[#14133b] contact-form__input border border-black"
                                                            placeholder="Confirm Password"
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    {newpassword === confirmPassword ? null : <label htmlFor="ConfirmPassword" className='text-red-600'>password Not match</label>}
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    {loading ? <Loader className={'contact-form__button'} /> : <button className="contact-form__button" >
                                                        Submit
                                                    </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}

                        </div>
                    </div>
                </section>
            </main> : <Navigate to="/" />}

            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
