import React, { useCallback, useEffect, useState } from 'react'
import { allDeletedEducatorsApi, allDeletedLearnersApi, allEducatorsApi, allLearnersApi, deleteEducatorApi, deleteLearnerApi } from '../../webServices/defaultApis'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { addStudents } from '../../redux/studentSlice'
import { Link } from 'react-router-dom'
import { addTeacher } from '../../redux/teacherSlice'

export default function TrashPage() {

    const dispatch = useDispatch()
    const [educators, setEducators] = useState([])
    const [learners, setLearners] = useState([])

    const getData = useCallback(async () => {
        let res1 = await allDeletedEducatorsApi()
        setEducators(res1)
        let res2 = await allDeletedLearnersApi()
        setLearners(res2)
    }, [])

    const RestoreEducator = async (id) => {
        let sure = window.confirm("Are You Sure Want To Restore ?")
        if (sure) {
            try {
                let response = await deleteEducatorApi(id);
                if (response.status) {
                    setEducators(educators.filter(item => item.id !== id))
                    let res = await allEducatorsApi()
                    dispatch(addTeacher(res))
                    toast.success("Educator Restore successfully")
                } else {
                    toast.error(response.data.message);
                }

            } catch (error) {
                console.error(error);
            }
        }
    };

    const RestoreLearner = async (id) => {
        let sure = window.confirm("Are You Sure Want To Restore ?")
        if (sure) {
            try {
                let response = await deleteLearnerApi(id);
                if (response.status) {
                    setLearners(learners.filter(item => item.id !== id))
                    let res = await allLearnersApi()
                    dispatch(addStudents(res))
                    toast.success("Learner Restore successfully")
                } else {
                    toast.error(response.data.message);
                }

            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <div className="container-fluid ps-10 pt-20" style={{ paddingLeft: "4rem" }}>
                <div className="row justify-center p-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 p-2 mb-4">
                        <div className="border-2">
                            <div className="bg-white overflow-x-auto">
                                <h2 className='text-center p-2 font-semibold'>Educators</h2>
                                <hr />
                                {educators && educators.length > 0 ?
                                    <table className="table table-striped table-hover min-w-full table-auto">
                                        <thead>
                                            <tr className='text-center'>
                                                <th className='fs-13 py-3 px-2'>Sr No</th>
                                                <th className='fs-13 py-3 px-2'>Name</th>
                                                <th className='fs-13 py-3 px-2'>Email</th>
                                                <th className='fs-13 py-3 px-2'>Mobile</th>
                                                <th className='fs-13 py-3 px-2'>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {educators && educators.map((item, idx) => (
                                                <tr className='text-center' key={idx}>
                                                    <td className='fs-13 py-3 px-2'>{idx + 1}</td>
                                                    <td className='fs-13 py-3 px-2'><Link to={`/dashboard/educator/${item?.id}`} >{item.fullname}</Link></td>
                                                    <td className='fs-13 py-3 px-2'>{item.email_id}</td>
                                                    <td className='fs-13 py-3 px-2'>{item.mobile}</td>
                                                    <td className='fs-13 py-3 px-2'>
                                                        <div className='flex justify-center'>
                                                            <button className='btn btn-outline-warning fs-13 ps-2 pe-2 pt-0 pb-0' onClick={() => { RestoreEducator(item.id) }}>Restore</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> : <h2 className='mt-2 mb-2 text-center'>Empty</h2>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                        <div className="border-2">
                            <div className="bg-white overflow-x-auto">
                                <h2 className='text-center p-2 font-semibold'>Learners</h2>
                                <hr />
                                {learners && learners.length > 0 ?
                                    <table className="table table-striped table-hover min-w-full table-auto">
                                        <thead>
                                            <tr className='text-center'>
                                                <th className='fs-13 py-3 px-2'>Sr No</th>
                                                <th className='fs-13 py-3 px-2'>Name</th>
                                                <th className='fs-13 py-3 px-2'>Email</th>
                                                <th className='fs-13 py-3 px-2'>Mobile</th>
                                                <th className='fs-13 py-3 px-2'>Option</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {learners && learners.map((item, idx) => (
                                                <tr key={idx} className='text-center'>
                                                    <td className='fs-13 py-3 px-2'>{idx + 1}</td>
                                                    <td className='fs-13 py-3 px-2'><Link to={`/dashboard/leaner/${item?.id}`} >{item.fullname}</Link></td>
                                                    <td className='fs-13 py-3 px-2'>{item.email_id}</td>
                                                    <td className='fs-13 py-3 px-2'>{item.mobile}</td>
                                                    <td className='fs-13 py-3 px-2'>
                                                        <div className='flex justify-center'>
                                                            <button className='btn btn-outline-warning ps-2 fs-13 pe-2 pt-0 pb-0' onClick={() => { RestoreLearner(item.id) }}>Restore</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> : <h2 className='mt-2 mb-2 text-center'>Empty</h2>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
