import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "user",
    initialState: {
        value: []
    },
    reducers: {
        addUser: (state, action) => {
            state.value = action.payload
        }
    }
})
export const { addUser } = slice.actions;
export default slice.reducer;