import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    let pathname = useLocation().pathname
    useEffect(() => {
        window.scroll(0, 0)
    }, [pathname])
    return;
}
