import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa6'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Header() {
    const [navOpen, setNavOpen] = useState(false)
    const navigate = useNavigate()
    const pathname = useLocation().pathname


    useEffect(() => {
        setNavOpen(false)
    }, [pathname])
    return (
        <>
            {/* ======= Header Start ======= */}
            <header className="header d-none d-lg-block">
                <div
                    id="topbar-wrap"
                    className="header-top header-top__border_bottom container-custom"
                >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <div className="header-top__info">
                                    <div className="container-fluid">
                                        <div className="header-bottom__row">
                                            <div className="header-bottom__row">
                                                <Link to="/" className="logo__link">
                                                    <img
                                                        src="assets/images/main_logo.png"
                                                        className="log-main"
                                                        alt="helloeducators"
                                                    />
                                                </Link>
                                                <div className=" main-menu__nav">
                                                    <ul>
                                                        <li className="list">
                                                            <Link className="animation text-blue" to="/learner">
                                                                Learner
                                                            </Link>
                                                        </li>
                                                        <li className="list">
                                                            <span>|</span>
                                                        </li>
                                                        <li className="list">
                                                            <Link className="animation text-blue" to="/educator">
                                                                Educator
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 d-none d-lg-block">
                                <div class="main-menu">
                                    <nav class="main-menu__nav">
                                        <ul className='flex gap-2'>
                                            <li>
                                                <button onClick={() => { navigate("/login") }} className='text-dark font-semibold text-lg btn btn-outline-primary lobtn'>
                                                    Login
                                                </button>
                                            </li>
                                            <li class="main-menu__nav_sub list d-flex justify-evenly">
                                                <Link class="animation text-dark register" to="/">Register</Link>
                                                <ul class="main-menu__dropdown hov">
                                                    <li><Link to="/register-learner">Register As Learner</Link></li>
                                                    <li><Link to="/register-educator">Register As Educattor</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div
                id="navbar-wrap"
                className="header-bottom container-custom navbar-wrap bg-blue d-none d-lg-block  position-sticky top-0 z-10"
            >
                <div className="container-fluid">
                    <div className="header-bottom__row">
                        <div className="header-bottom__col" style={{ width: "100%" }}>
                            <div className="main-menu">
                                <nav className="main-menu__nav">
                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <ul>
                                            <li className="active">
                                                <Link className="animation" to="/">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <Link className="animation" to="/about-us">
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className="list">
                                                <Link className="animation" to="/why-hello-educator">
                                                    Why Hello Educator
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="animation" to="/how-it-work">
                                                    How It Works
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="animation" to="/subject-we-cover">
                                                    Subjects We Cover
                                                </Link>
                                            </li>
                                            <li className="list">
                                                <Link className="animation" to="/contact-us">
                                                    Contact Us
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Menu Start */}
            <div
                className="rt-header-menu mean-container bg-blue position-sticky top-0 z-10"
                id="meanmenu"
            >
                <div className="mean-bar">
                    <Link to="/">
                        <img
                            className="log-main"
                            alt="Hello Educators"
                            src="assets/images/main_logo-white.png"
                        />
                    </Link>

                    <div className="sidebarBtn" onClick={() => { setNavOpen(!navOpen) }}>
                        <FaBars size={20} color='white' />
                    </div>
                </div>
                <div className={navOpen ? "rt-show bg-white" : "rt-slide-nav"}>
                    <div className="offscreen-navigation">
                        <nav className="menu-main-primary-container">
                            <ul className="menu">
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/why-hello-educator">
                                        Why Hello Educator
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/how-it-work">
                                        How it Works
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/subject-we-cover">
                                        Subjects We Cover
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/contact-us">
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/learner">
                                        Learner
                                    </Link>
                                </li>
                                <li className="list menu-item-parent">
                                    <Link className="animation" to="/educator">
                                        Educator
                                    </Link>
                                </li>
                                <hr />
                                <li className="list menu-item-parent">
                                    <button onClick={() => { navigate("/login") }} className="text-dark font-semibold text-md btn btn-outline-primary lobtn">
                                        Login
                                    </button>
                                </li>
                                <li className="list menu-item-parent">
                                    <div class="dropdown">
                                        <button class="btn btn-outline-primary text-dark lobtn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Register
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><Link class="dropdown-item" to='/register-learner'>Register As Learner</Link></li>
                                            <li><Link class="dropdown-item" to='/register-educator' >Register As Educator</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* Mobile Menu Start End */}
            {/* ======= Header End ======= */}
        </>
    )
}
