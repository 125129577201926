import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLearnerApi } from '../webServices/defaultApis';
import { delStudents } from '../redux/studentSlice';
import { Auth } from '../utils/helper';


export default function Student() {
    const studentData = useSelector((store) => store.students.value)
    const teacherData = useSelector((store) => store.teachers.value)
    const assignLearner = useSelector((store) => store.assign.value)
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const [StudentsPerPage] = useState(4);
    const navigate = useNavigate()

    // Calculate the indexes for the current page
    const indexOfLastStudent = currentPage * StudentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - StudentsPerPage;

    const currentStudents = studentData && studentData?.slice(indexOfFirstStudent, indexOfLastStudent);

    // Handle pagination
    const handleNextPage = () => {
        if (currentPage < Math.ceil(studentData.length / StudentsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDeleteStudent = async (id) => {
        let sure = window.confirm("Are You Sure Want To Delete ?")
        if (sure) {
            try {
                let response = await deleteLearnerApi(id);
                if (response.status) {
                    dispatch(delStudents(id))
                    toast.success("Learner deleted successfully")
                } else {
                    toast.error(response.data.message);
                }

            } catch (error) {
                console.error(error);
            }
        }
    };

    function assignTech(id) {
        let arr = []
        let ass = assignLearner && assignLearner.filter(item => item.student === id)
        for (let i of teacherData && teacherData) {
            for (let j of ass && ass) {
                if (i.id === j.teacher) {
                    let Obj = {
                        fullname: i.fullname,
                        subject: j.subject,
                        id: i.id
                    }
                    arr.push(Obj)
                }
            }
        }
        return arr;
    }
    return (
        <>
            {Auth() === "admin" ? <div className="container-fluid pt-10 ps-8">
                <div className="row justify-end gap-5 p-0">
                    <div className="col-lg-8 col-md-8 col-sm-12 mb-4 ms-3">
                        <div className="space-y-6 pt-8 ps-8">
                            <h1 className="font-bold text-xl text-gray-700 text-center">
                                Learners Details
                            </h1>
                            {currentStudents?.map((studentData, index) => (
                                <div key={index} className="bg-white rounded-lg border-b-8 border-[#030b47] shadow p-6">
                                    {studentData.isNew ? <div className="mt-1 flex justify-end space-x-2">
                                        <button
                                            className="bg-[#ee241a] text-white rounded-md font-semibold px-4 py-1 new-animation"
                                            title='View'
                                        >
                                            New
                                        </button>
                                    </div> : null}
                                    <div className="grid ms:grid-cols-1 md:grid-cols-2 gap-4 mt-1">
                                        <p><strong>Full Name:</strong> {studentData.fullname}</p>
                                        <p><strong>Father Name:</strong> {studentData.fathername}</p>
                                        <p><strong>Mobile Number:</strong> {studentData.mobile}</p>
                                        <p><strong>Email Id:</strong> {studentData.email_id}</p>
                                        <p><strong>Stream:</strong> {studentData.stream}</p>
                                        {studentData.stream === "Research" && <p><strong>Research Project Enrolled:</strong> {studentData.research_project.split("_")?.map((item, idx) => (
                                            <li key={idx} type="square">{item}</li>
                                        ))}</p>}
                                        {studentData.stream === "Research" && <p><strong>Area of Research:</strong> {studentData.area_of_research}</p>}
                                        {studentData.stream !== "Research" && <p><strong>Subject Enrolled:</strong> {studentData.subject.split("_")?.map((item, idx) => (
                                            <li key={idx} type="square">{item}</li>
                                        ))}</p>}
                                        <p><strong>{studentData.stream === "Research" ? "Research Project Assigned:" : "Subject Assigned:"}</strong> {assignTech(studentData.id).map((ele, idx) => (<li type="square" key={idx}><b></b>{ele.subject}</li>))}</p>
                                        <p><strong>Educator Assigned:</strong> {assignTech(studentData.id).map((ele, idx) => (<li type="square" key={idx}><b><Link to={`/dashboard/educator/${ele.id}`}>{ele.fullname}</Link></b></li>))}</p>
                                    </div>
                                    <div className="mt-1 flex justify-end space-x-2">
                                        <button
                                            onClick={() => { navigate(`/dashboard/learner/${studentData?.id}`) }}
                                            className="bg-[#030b47] text-white rounded-md hover:bg-sky-700 font-semibold px-4 py-1 flex items-center gap-1"
                                            title='View'
                                        >
                                            <FaEye />
                                        </button>
                                        <button
                                            onClick={() => handleDeleteStudent(studentData.id)}
                                            className="bg-red-400 text-white rounded-md hover:bg-red-500 font-semibold px-4 py-1 flex items-center gap-1"
                                            title='Delete'
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex space-x-4 mt-6 ps-8">
                            <button
                                onClick={handlePrevPage}
                                className="bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 font-semibold px-4 py-2"
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                className="bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 font-semibold px-4 py-2"
                                disabled={currentPage >= Math.ceil(studentData.length / StudentsPerPage)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/dashboard" />}
        </>
    );
}
