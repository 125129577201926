import React, { useCallback, useEffect, useState } from 'react'
import Profile from '../../componets/profile'
import { LearnerProfileApi } from '../../webServices/profileApis';
import { Navigate, useParams } from 'react-router-dom';
import { learnerPermissionApi } from '../../webServices/permissionApis';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { addStudents } from '../../redux/studentSlice';
import { Auth } from '../../utils/helper';
import { allLearnersApi } from '../../webServices/defaultApis';


export default function LearnerProfilePage() {
    let user = Auth()
    const { id } = useParams()
    const dispatch = useDispatch()
    const Allassign = useSelector((store) => store.assign.value)
    const [learnerData, setLeanerData] = useState()
    const [refresh, setRefresh] = useState(false)
    const [loading1, setLoading1] = useState(false)

    const fetchProfile = useCallback(async () => {
        let response = await LearnerProfileApi(id)
        setLeanerData(response)
    }, [id])

    const GivenPermission = async (id) => {
        setLoading1(true)
        let response = await learnerPermissionApi(id)
        if (response?.status) {
            setLoading1(false)
            setRefresh(!refresh)
            toast.success(response?.message)
        } else {
            setLoading1(false)
        }
    };

    useEffect(() => {
        async function get() {
            fetchProfile()
            dispatch(addStudents(await allLearnersApi()))
        }
        get()
    }, [refresh])

    return (
        <>
            {user === "admin" ? <Profile refresh={setRefresh} Data={learnerData} isEditButtonShow={false} permission={GivenPermission} loading1={loading1} assignLearners={Allassign && Allassign.filter((item) => item.student === id)} /> : <Navigate to="/dashboard" />}
        </>
    )
}
