import React from 'react'
import About from '../../componets/about'
import HowWork from '../../componets/howWork'
import WhyHello from '../../componets/whyHello'
import CoverSubject from '../../componets/coverSubject'
import SimpleSlider from '../../componets/simpleSlider'

export default function HomePage() {
    return (
        <>
            <main className="main-wrapper">
                {/* ======= Hero Start ======= */}
                <section class="slider-container">
                    {/* <div className='row justify-center'>
                        <div className='col-12'> */}
                    <SimpleSlider />
                    {/* </div>
                    </div> */}
                </section>
                {/* ======= Hero End ======= */}
                <About />
                <WhyHello />
                <HowWork />
                <CoverSubject />
            </main>
        </>
    )
}
