import React from 'react'
import About from '../../componets/about'
import HeroSection from '../../componets/heroSection'

export default function AboutPage() {
    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"About Us"} />
                <About />
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
