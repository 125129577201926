import React, { useState } from 'react'
import { axiosClient } from '../../webServices/WebURL'
import { Auth } from '../../utils/helper'
import { webURLs } from '../../webServices/GateWay'
import ChangePassForm from '../../componets/changePassForm'
import toast from 'react-hot-toast'
import { Navigate } from 'react-router-dom'

export default function EducatorPasswordChange() {

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (old, newPass) => {
        setLoading(true)
        try {
            if (Auth() === "educator") {
                let res = await axiosClient().put(webURLs.CHANGE_EDUCATOR_PASS, { oldPassword: old, newPassword: newPass })
                if (res.data.status) {
                    setLoading(false)
                    toast.success(res.data.message)
                } else {
                    setLoading(false)
                    toast.error(res.data.message)
                }
            }
        } catch (error) {
            setLoading(false)
            toast.error("Network Error")
        }
    }

    return (
        <>
            {Auth() === "educator" ? <div className="container-fluid ps-20 pt-24">
                <div className="row justify-center pt-4">
                    <div className="col-lg-5 col-md-5 col-sm-10 mb-4">
                        <div className="bg-white p-4 shadow rounded-sm border-b-8 flex justify-center border-[#030b47]">
                            <ChangePassForm handleSubmit={handleSubmit} loading={loading} />
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/dashboard" />}
        </>
    )
}
