import { configureStore } from "@reduxjs/toolkit";
import teacherSlice from "./teacherSlice";
import studentSlice from "./studentSlice";
import userSlice from "./userSlice";
import assignEducatorSlice from "./assignEducatorSlice";


const store = configureStore({
    reducer: {
        teachers: teacherSlice,
        students: studentSlice,
        User: userSlice,
        assign: assignEducatorSlice
    }
})
export default store;