import React from 'react'
import WhyHello from '../../componets/whyHello'
import HeroSection from '../../componets/heroSection'

export default function WhyHelloPage() {
    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"Why Hello Educator"}/> 
                <WhyHello />
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
