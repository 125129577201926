export const webURLs = {
    LOGIN_USER: "/v1/userLogin",
    REGISTER_EDUCATOR: "/v1/educatorRegister",
    REGISTER_LEARNER: "/v1/learnerRegister",
    SEND_OTP: "/v1/send-otp/",
    SEND_ENQUIRY: "/v1/send-enquiry/",
    ALL_COUNT: "/v1/all-count",
    VERIFY_OTP: "/v1/otp/verify-otp",
    UPDATE_PASSWORD: "/v1/otp/change-password",
    GET_ALL_EDUCATORS: "/api/educator",
    GET_ALL_LEARNERS: "/api/learner",
    DELETE_AND_UNDU_LEARNER: "/api/learner/delete/", //${id}
    DELETE_AND_UNDU_EDUCATOR: "/api/educator/delete/", //${id}
    GET_DELETED_EDUCATORS: "/api/educator/trash",
    GET_DELETED_LEARNERS: "/api/learner/trash",
    ADMIN_PROFILE: "/api/admin/",
    UPDATE_ADMIN: "/api/admin//update/", // :id
    UPDATE_LEARNER: "/api/learner//update/", // :id
    UPDATE_EDUCATOR: "/api/educator//update/", // :id
    LEARNER_PROFILE: "/api/learner/", // :id
    EDUCATOR_PROFILE: "/api/educator/", // :id
    LEARNER_PERMISSION: "/api/learner/permission/", // :id
    EDUCATOR_PERMISSION: "/api/educator/permission/", // :id
    ASSIGN_EDUCATOR: "/api/admin/assign-learner/", //Studentid
    ASSIGN_ALL_EDUCATOR: "/api/admin/all-assign-learners/", //Studentid
    DELETE_ASSIGN_EDUCATOR: "/api/admin/delete-assign-learners/", //:id
    ALL_ASSIGN: "/api/admin/all-assign",
    CHANGE_LEARNER_PASS: "/api/learner/change-password",
    CHANGE_EDUCATOR_PASS: "/api/educator/change-password",
    CHANGE_ADMIN_PASS: "/api/admin/change-password",
    ALL_ENQUIRY: "/api/enquiries/",
    DELETE_ENQUIRY: "/api/enquiries/delete/", // id
    EDUCATOR_UPLOAD_PROFILE: "/api/educator/upload-profile",
    LEARNER_UPLOAD_PROFILE: "/api/learner/upload-profile",
    LEARNER_UPLOAD_ID_CARD: "/api/learner/upload-idcard",
    ADMIN_UPLOAD_PROFILE: "/api/admin/upload-profile",
    EDUCATOR_UPLOAD_CV: "/api/educator/upload-cv",
    EDUCATOR_UPLOAD_DEGREE: "/api/educator/upload-degree",
    EDUCATOR_UPLOAD_VIDEO: "/api/educator/upload-video",
}