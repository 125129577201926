import React, { useState } from 'react'
import { Subject } from '../../utils/subjectdata'
import HeroSection from '../../componets/heroSection'
import { Colors } from '../../utils/helper'

export default function SubjectsPage() {
    const [allSubject, setAllSubject] = useState(Subject)

    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main >
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"Subjects We Cover"} />
                {/* ======= Why Choose Area Start ======= */}
                <section className="services section-startup-bg mt-3">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="">
                                    <span className="section-heading__title_small">
                                        Subject We Cover
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {allSubject && allSubject.map((ele, idx) => (
                    <section className="services section-startup-bg" key={idx}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-heading section-heading--startup text-center mb-1">
                                        <span className="section-heading__title_small mt-10 text-white" style={{ background: Colors[idx] }}>
                                            {ele.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row overflow-hidden">
                                {ele?.subject.map((item, ind) => (
                                    <div
                                        className="col-lg-3 col-sm-6 col-md-6 wow fadeInUp mb-3 animated"
                                        data-wow-delay=".2s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.2s",
                                            animationName: "fadeInUp"
                                        }}
                                        key={ind}
                                    >
                                        <a href="#">
                                            <div className="col-lg-12 col-md-3 btn waves-effect waves-light">
                                                <div
                                                    className="btn btn12 tabInactive waves-effect waves-light"
                                                    style={{ backgroundColor: Colors[ind], color: "white" }}
                                                >
                                                    <i className="icon-play" /> &nbsp; {item}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                ))}
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
