import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows : true
    };
    return (
        <Slider {...settings}  >
            <div className="slider__single slider__bg1 container-custom">
            </div>
            <div className="slider__single slider__bg3 container-custom">
            </div>
            <div className="slider__single slider__bg2 container-custom" >
            </div>
        </Slider>
    );
}