import axios from "axios";
import { Token } from "../utils/helper";

export function axiosClient() {
  const token = Token()
  return axios.create({
    baseURL: "https://api.helloeducators.in",
    // baseURL: "http://localhost:5010",
    headers: {
      "Content-Type": "Application/JSON",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  })
}
