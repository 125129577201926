import toast from "react-hot-toast";
import { webURLs } from "./GateWay";
import { axiosClient } from "./WebURL";

export async function SendOtpApi(data) {
    try {
        const response = await axiosClient().post(webURLs.SEND_OTP, data)
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function VerifyOtpApi(token, data) {
    try {
        const response = await axiosClient().post(webURLs.VERIFY_OTP, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function updatePassword(token, data) {
    try {
        const response = await axiosClient().put(webURLs.UPDATE_PASSWORD,data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        if (response.data.status) {
            return response.data;
        }
        else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error)
    }
}


