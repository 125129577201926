import React from 'react'

export default function WhyHello() {
    return (
        <>
            {/* ======= Why Choose Area Start ======= */}
            <section className="careers mt-3 mb-5">
                <div className="container">
                    <div className="row justify-center">
                        <div className="section-heading">
                            <span className="section-heading__title_small">
                                WHY HELLO EDUCATOR
                            </span>
                            <p className="text-justify mt-3">
                                <strong>Hello Educator</strong> is a premier digital educational platform that offers the best-quality lectures at
                                home from India’s best teachers, lecturers, professors, experienced professional educators, and
                                relevant postgraduates, i.e., mathematicians, engineers, scientists and doctors.
                            </p>
                            <p className='text-justify mt-3'>
                                <strong>Hello Educator </strong>also offers research guidance to undergraduate students, postgraduate students,
                                and PhD scholars for their research projects from experienced professional educators working in
                                science, engineering and medical fields.
                            </p>
                        </div>
                        <div className='col-12 p-1 flex justify-center'>
                            <div className='col-lg-8 col-md-8 col-sm-10 mt-5'>
                                <img src="assets/images/home1/why-hello-eduactor.jpg" className='rounded' alt="why hello" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
