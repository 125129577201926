import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Auth, DateFormate } from "../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../webServices/WebURL";
import { webURLs } from "../webServices/GateWay";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import EditModel from "./editModel";
import { addSingleAssignEducator, deleteAssignEducator } from "../redux/assignEducatorSlice";
import { deleteAssignEdu } from "../webServices/defaultApis";
import Loader from "./loader";
import AdminEditModel from "./adminEditModel";

function Profile({ refresh, Data, permission, assignLearners, loading1, isEditButtonShow }) {
    const dispatch = useDispatch()
    const teachers = useSelector((store) => store.teachers.value)
    const students = useSelector((store) => store.students.value)
    const [selectedSubject, setSelectedSubject] = useState('');
    const [teacherName, setTeacherName] = useState('');
    const [selectedTeacher, setSelectedTeacher] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [adminModal, setAdminModal] = useState(false)
    const [modalData, setModalData] = useState()
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const user = Auth()

    const handleSubmit = async (e) => {
        if (selectedSubject === "" || teacherName === "") {
            toast.error("Please Select Teacher And Subject")
            return
        }
        e.preventDefault();
        try {
            setLoading(true)
            let response = await axiosClient().post(`${webURLs.ASSIGN_EDUCATOR}${id}`, {
                subject: selectedSubject,
                teacher: teacherName,
                student: id
            })
            if (response.data.status) {
                setLoading(false)
                e.target.reset()
                refresh(response.data.message)
                dispatch(addSingleAssignEducator(response.data.data))
                toast.success(response.data.message)
            }
            else {
                setLoading(false)
                toast.error(response.data.message)
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.message)
        }
    }

    function selectSubject(e) {
        const { value } = e.target
        if (value !== "") {
            setSelectedSubject(value)
            const filteredTeachers = teachers && teachers?.filter((teacher) => teacher.subject.split("_").includes(value) || teacher.research_project.split("_").includes(value))
            setSelectedTeacher(filteredTeachers)
        } else {
            setSelectedSubject("")
            setSelectedTeacher([])
        }
    }


    const handleDeleteFunction = async (id) => {
        let isDelete = window.confirm("Are you Sure Want To Delete ?")
        if (isDelete) {
            try {
                let res = await deleteAssignEdu(id)
                if (res.status) {
                    dispatch(deleteAssignEducator(id))
                    refresh(res.message)
                    toast.success(res.message)
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    }

    function openModel() {
        setModalData(Data)
        setShowModal(true)
    }

    // function assignStudent(id) {
    //     let arr = []
    //     let ass = assignLearner && assignLearner.filter(item => item.teacher === id)
    //     for (let i of students && students) {
    //         for (let j of ass && ass) {
    //             if (i.id === j.student) {
    //                 let Obj = {
    //                     fullname: i.fullname,
    //                     subject: j.subject,
    //                     id: i.id
    //                 }
    //                 arr.push(Obj)
    //             }
    //         }
    //     }
    //     return arr;
    // }

    // function assignTeacher(id) {
    //     let arr = []
    //     let ass = assignLearner && assignLearner.filter(item => item.student === id)
    //     for (let i of teachers && teachers) {
    //         for (let j of ass && ass) {
    //             if (i.id === j.teacher) {
    //                 let Obj = {
    //                     fullname: i.fullname,
    //                     subject: j.subject,
    //                     id: i.id
    //                 }
    //                 arr.push(Obj)
    //             }
    //         }
    //     }
    //     return arr;
    // }

    return (
        <div className="container-fluid ps-10 pt-20" style={{ paddingLeft: "4rem" }}>
            <div className="row p-0 justify-center">
                <div className="col-lg-4 col-md-4 col-sm-10 mb-4 bg-white">
                    <div className="p-3 border border-[#030b47]">
                        <div className="image overflow-hidden flex justify-center">
                            {Data?.image ? <img className="h-[120px] w-[120px] border-2 border-[#120365] bg-emerald-200 rounded-full" src={Data?.image} alt={Data?.fullname} />
                                : <CiUser fontSize={125} className="rounded-full p-3 border-2 border-[#120365]" />}
                        </div>
                        <h1 className="text-gray-900 font-bold text-xl leading-8 my-1 text-capitalize">{Data?.fullname}</h1>
                        <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                            <li className="flex items-center py-3">
                                <span className="font-bold">Account Status</span>
                                <span className="ml-auto">{Data?.isDelete ? <span className="bg-[#ee241a] py-1 px-2 rounded text-white text-sm">Deactivate</span> : <span className="bg-[#08b330] py-1 px-2 rounded text-white text-sm">Active</span>}</span>
                            </li>
                            <li className="flex items-center py-3">
                                <span className="font-bold">Is Verified</span>
                                <span className="ml-auto">{Data?.isAccess ? <span className="bg-[#08b330] py-1 px-2 rounded text-white text-sm">verified</span> : <span className="bg-[#ee241a] py-1 px-2 rounded text-white text-sm">Not Verified</span>}</span>
                            </li>
                            <li className="flex items-center py-3">
                                <span className="font-bold">Identification Type</span>
                                <span className="ml-auto"><span className="py-1 px-2 text-black rounded text-sm text-capitalize">{Data?.identification_type ? Data?.identification_type : "Aadhar Card"}</span></span>
                            </li>
                            <li className="flex items-center py-3">
                                <span className="font-bold">Identification Number</span>
                                <span className="ml-auto"><span className="py-1 px-2 text-black rounded text-sm text-capitalize">{Data?.identification_no ? Data?.identification_no : 384256534948}</span></span>
                            </li>
                            {Data?.role === "learner" && <li className="flex items-center py-3">
                                <span className="font-bold">Id Card File</span>
                                <span className="ml-auto"><a href={Data?.id_card_image} className="bg-[#08b330] py-1 px-2 rounded text-white text-sm">View</a></span>
                            </li>}
                            {Data?.role === "educator" && <li className="flex items-center py-3">
                                <span className="font-bold">Resume</span>
                                <span className="ml-auto"><a href={Data?.upload_cv} className="bg-[#08b330] py-1 px-2 rounded text-white text-sm">View File</a></span>
                            </li>}
                            {Data?.role === "educator" && <li className="flex items-center py-3">
                                <span className="font-bold">Degree</span>
                                <span className="ml-auto"><a href={Data?.upload_degree} className="bg-[#08b330] py-1 px-2 rounded text-white text-sm">View File</a></span>
                            </li>}
                            <li className="flex items-center py-3">
                                <span className="font-bold">Member since</span>
                                <span className="ml-auto">{DateFormate(Data?.createdAt)}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-10 mb-4 border bg-white shadow ">
                    <div className=" p-3 border-[#030b47]">
                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                            <span className="text-green-500">
                                <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </span>
                            <span className="tracking-wide">About</span>
                        </div>
                        <div className="text-sm text-capitalize row">
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Full Name:</span>&nbsp;<span className="text-sm font-normal">{Data?.fullname}</span>
                                </div>
                            </div>
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Father Name:</span>&nbsp;<span className="text-sm font-normal">{Data?.fathername}</span>
                                </div>
                            </div>
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Mobile:</span>&nbsp;<span className="text-sm font-normal">{Data?.mobile}</span>
                                </div>
                            </div>
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Email:</span>&nbsp;<span className="text-sm font-normal"><a className="text-blue-800" style={{ textTransform: "none" }} href={`mailto:${Data?.email_id}`}>{Data?.email_id}</a></span>
                                </div>
                            </div>
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">DOB:</span>&nbsp;<span className="text-sm font-normal">{DateFormate(Data?.date_Of_birth)}</span>
                                </div>
                            </div>)}
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Gender:</span>&nbsp;<span className="text-sm font-normal">{Data?.gender}</span>
                                </div>
                            </div>
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Address:</span>&nbsp;<span className="text-sm font-normal">{Data?.present_address}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">State:</span>&nbsp;<span className="text-sm font-normal">{Data?.state_name}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">City:</span>&nbsp;<span className="text-sm font-normal">{Data?.city_name}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Landmark:</span>&nbsp;<span className="text-sm font-normal">{Data?.landmark}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Pin Code:</span>&nbsp;<span className="text-sm font-normal">{Data?.pin_code}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Language:</span>&nbsp;<span className="text-sm font-normal">{Data?.language}</span>
                            </div></div>)}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Preferred Mode:</span>&nbsp;<span className="text-sm font-normal">{Data?.class_mode}</span>
                            </div></div>)}
                            {Data?.role === "educator" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Education Qualification:</span>&nbsp;<span className="text-sm font-normal text-uppercase">{Data?.qualification === "phd" ? "PhD" : Data?.qualification}</span>
                            </div></div>}
                            {Data?.role === "educator" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Name of University/Institute:</span>&nbsp;<span className="text-sm font-normal">{Data?.Orginization_name}</span>
                            </div></div>}
                            {Data?.role === "learner" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold"> Name of Board/University:</span>&nbsp;<span className="text-sm font-normal">{Data?.Orginization_name}</span>
                            </div></div>}
                            {Data?.role === "learner" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Name of School/College:</span>&nbsp;<span className="text-sm font-normal">{Data?.school_name}</span>
                            </div></div>}
                            {Data?.role === "educator" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Name of Course/Specialization:</span>&nbsp;<span className="text-sm font-normal">{Data?.course_name}</span>
                            </div></div>}
                            {Data?.role === "educator" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Current Designation :</span>&nbsp;<span className="text-sm font-normal">{Data?.current_designation}</span>
                            </div></div>}
                            {Data?.role === "educator" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Experience:</span>&nbsp;<span className="text-sm font-normal">{Data?.experience}</span>
                            </div></div>}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Stream:</span>&nbsp;<span className="text-sm font-normal">{Data?.stream}</span>
                            </div></div>)}
                            {Data?.stream === "Research" && <div className="text-sm col-lg-6 col-md-6 col-sm-12">  <div className="mt-2">
                                <span className="text-stone-950 font-semibold">Research Project Enrolled:</span>&nbsp;{Data?.research_project?.split("_")?.map((item, idx) => (
                                    <li type="square" className="text-sm font-normal ms-1" key={idx}>{item}</li>
                                ))}
                            </div></div>}
                            {(Data?.role === "learner" || Data?.role === "educator") && (Data?.stream !== "Research" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Subject Enrolled:</span>&nbsp;{Data?.subject?.split("_")?.map((item, idx) => (
                                    <li type="square" className="text-sm font-normal ms-1" key={idx}>{item}</li>
                                ))}
                            </div></div>)}
                            {Data?.stream === "Research" && <div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Area of Research:</span>&nbsp;<span className="text-sm font-normal">{Data?.area_of_research}</span>
                            </div></div>}
                            {(Data?.role === "learner" || Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Class:</span>&nbsp;{Data?.class_name?.split(" ")?.map((item, idx) => (
                                    <li type="square" className="text-sm font-normal ms-1" key={idx}>{item}</li>
                                ))}
                            </div></div>)}
                            {(Data?.role === "learner") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">{Data?.stream === "Research" ? "Research Project Assigned:" : "Subject Assigned:"}</span>&nbsp;{Data?.assign?.map((item, idx) => (
                                    <li type="square" className="text-sm font-normal ms-1" key={idx}>{item.subject}</li>
                                ))}
                            </div></div>)}
                            {(Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">{Data?.stream === "Research" ? "Research Project Assigned:" : "Subject Assigned:"}</span>&nbsp;{Data?.assign?.map((item, idx) => (
                                    <li type="square" className="text-sm font-normal ms-1" key={idx}>{item.subject}</li>
                                ))}
                            </div></div>)}
                            <div className="text-sm col-lg-6 col-md-6 col-sm-12">
                                <div className="mt-2">
                                    <span className="text-stone-950 font-semibold">Role:</span>&nbsp;<span className="text-sm font-normal">{Data?.role}</span>
                                </div>
                            </div>
                            {/* {(Data?.role === "educator") && (<div className="text-sm col-lg-6 col-md-6 col-sm-12"><div className="mt-2">
                                <span className="text-stone-950 font-semibold">Number of Learner Assigned:</span>&nbsp;{Data?.assign?.length}
                            </div></div>)} */}
                        </div>
                        <div className="flex justify-end gap-3">
                            {user === "admin" && <>{loading1 ? <Loader className={'text-[#030b47] text-center text-sm font-bold rounded bg-[#030b47] focus:outline-none focus:shadow-outline hover:shadow-xs p-2 hover:text-white'} /> : <button onClick={() => { permission(Data?.id) }} className=" text-[#030b47] text-center text-sm font-bold rounded hover:bg-[#030b47] focus:outline-none focus:shadow-outline hover:shadow-xs p-2 hover:text-white">{Data?.isAccess ? "Access Denied" : "Verify Here"}</button>}</>}
                            {(isEditButtonShow && user !== "admin") && <button onClick={openModel} className="text-[#030b47] text-center text-xl font-bold rounded hover:bg-[#030b47] focus:outline-none focus:shadow-outline hover:shadow-xs p-2 hover:text-white">
                                <FaEdit />
                            </button>}
                            {(isEditButtonShow && user === "admin") && <button onClick={() => { setAdminModal(true) }} className="text-[#030b47] text-center text-xl font-bold rounded hover:bg-[#030b47] focus:outline-none focus:shadow-outline hover:shadow-xs p-2 hover:text-white">
                                <FaEdit />
                            </button>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-10 mb-4">
                    {Data?.role === "educator" && <div className="p-3 border shadow bg-white border-[#030b47]">
                        <span>Demo video</span>
                        <video controls >
                            <source src={Data?.demo_video} type="video/mp4" />
                        </video>
                        <div className="flex justify-end">
                            <Link className="mt-2 p-2 bg-cyan-600" to={Data?.demo_video} download={true}>Download</Link>
                        </div>
                    </div>}
                </div>
                <div className="col-lg-7 col-md-7 col-sm-10 mb-4 border bg-white">
                    {user === "admin" && <div className="">
                        {(Data?.role === "learner" && Data?.isAccess) && (<div className="p-3 border-b-2 border-[#030b47]">
                            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                <span className="text-green-500">
                                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg>
                                </span>
                                <span className="tracking-wide">Assign Educator To Learner</span>
                            </div>
                            <div className="text-sm">
                                <form onSubmit={(e) => handleSubmit(e)} className="flex gap-4 items-center">
                                    <select
                                        name="subject"
                                        className="contact-form__input"
                                        required
                                        onChange={selectSubject}
                                    >
                                        <option value="">-Subject Enrolled-</option>
                                        {(Data?.subject.length ? Data?.subject?.split("_") : Data?.research_project?.split("_"))?.map((subject, index) => (
                                            <option key={index} value={subject}>{subject}</option>))}
                                    </select>

                                    <select
                                        name="class_name"
                                        className="contact-form__input"
                                        required
                                        onChange={(e) => setTeacherName(e.target.value)}
                                    >
                                        <option value="">-Educator-</option>
                                        {selectedTeacher?.map((teacher, index) => (
                                            <option value={teacher.id} key={index}>{teacher.fullname}</option>
                                        ))}

                                    </select>
                                    {loading ? <Loader /> : <button type="Submit" className=" text-[#030b47] text-center text-md font-bold rounded hover:bg-[#030b47] border-2 hover:shadow-xs px-2 hover:text-white h-8 ">Assign</button>}
                                </form>
                            </div>
                            <div className="overflow-x-auto mt-4">
                                <table className="min-w-full table-auto">
                                    <thead className="bg-gray-200">
                                        <tr>
                                            <th className="text-center fs-13 py-3 px-2">S.No.</th>
                                            <th className="text-center fs-13 py-3 px-2">Subject/Project Enrolled</th>
                                            <th className="text-center fs-13 py-3 px-2">Subject/Project Assigned</th>
                                            <th className="text-center fs-13 py-3 px-2">Educator Assigned</th>
                                            <th className="text-center fs-13 py-3 px-2">Date Assigned</th>
                                            <th className="text-center fs-13 py-3 px-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assignLearners && assignLearners?.map((item, id) => (
                                            <tr className="bg-white border-b" key={id}>
                                                <td className="text-center py-3 px-2" data-label="S.No.">{id + 1}</td>
                                                <td className="text-center py-3 px-2" data-label="Subject">{item.subject}</td>
                                                <td className="text-center py-3 px-2" data-label="Subject">{item.subject}</td>
                                                <td className="text-center py-3 px-2" data-label="Assigned Educator">{(teachers && teachers?.find(ele => ele.id === item.teacher))?.fullname}</td>
                                                <td className="text-center py-3 px-2" data-label="Subject">{DateFormate(item.createdAt)}</td>
                                                <td className="text-center py-3 px-2"><button
                                                    className="bg-red-400 text-white rounded-md hover:bg-red-500 px-2 py-2"
                                                    onClick={() => handleDeleteFunction(item.id)}
                                                    title="Delete"
                                                >
                                                    <MdDelete />
                                                </button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>)}
                    </div>}
                </div>
            </div>
            {showModal && <EditModel Data={modalData} setShowModal={setShowModal} showModal={showModal} modelTitle={Data?.role} />}
            {adminModal && <AdminEditModel adminModal={adminModal} setAdminModal={setAdminModal} Data={Data} modelTitle={Data?.role} />}
        </div>
    );
}

export default Profile;


