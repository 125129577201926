import React from 'react'
import HeroSection from '../../componets/heroSection'
import { useLocation } from 'react-router-dom'

export default function Registerpage({ children }) {
    const pathname = useLocation().pathname.replace("/","")
    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={pathname.replace("-", " As ")} />
                {/* ======= Why Choose Area Start ======= */}
                <section className="services section-startup-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt-3 mb-3">
                                <div className="contact-form--main" id="contact-form">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
