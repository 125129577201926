import React, { useState } from 'react'
import { passwordValidator } from '../utils/helper'
import toast from 'react-hot-toast'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'
import Loader from './loader'

export default function ChangePassForm({ handleSubmit, loading }) {
    const [oldpassword, setOldPassword] = useState()
    const [newpassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [passwordError, setPasswordError] = useState()
    const [visible, setVisible] = useState(false)

    function ValidatePass(value) {
        if (passwordValidator(value)) {
            setNewPassword(value)
            setPasswordError(false)
        } else {
            setNewPassword(false)
            setPasswordError("password should be contains atleast One character ,One digit and One Spacial character,minimum password length is 6")
        }
    }

    async function Submit(e) {
        e.preventDefault()
        if (newpassword === false) {
            toast.error("enter valid Password")
            return;
        }
        if (newpassword === confirmPassword) {
            await handleSubmit(oldpassword, newpassword)
            e.target.reset()
            return;
        } else {
            toast.error("Password is Not Match")
            return;
        }
    }

    return (
        <>
            <form onSubmit={Submit}>
                <div className="col-md-12 col-lg-12 col-sm-12 mt-2">
                    <input
                        name="password"
                        type="text"
                        className="w-full text-[#14133b] contact-form__input border border-black"
                        placeholder="Old Password"
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='col-12'>
                    <div className="col-md-12 col-md-12 col-lg-12 col-sm-12 flex items-center contact-form__input border border-black">
                        <input
                            name="password"
                            type={visible ? "text" : "password"}
                            className="w-full text-[#14133b]"
                            placeholder="New Password"
                            onChange={(e) => { ValidatePass(e.target.value) }}
                            required
                        />
                        <div className='cursor-pointer' onClick={() => setVisible(!visible)}>
                            {visible ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    </div>
                    {passwordError ? <label htmlFor="" className='text-red-600'>{passwordError}</label> : null}
                </div>

                <div className='col-12'>
                    <div className="col-md-12 col-md-12 col-lg-12 col-sm-12">
                        <input
                            name="password"
                            type="password"
                            className="w-full text-[#14133b] contact-form__input border border-black"
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />

                    </div>
                    {newpassword === confirmPassword ? null : <label htmlFor="ConfirmPassword" className='text-red-600'>password Not match</label>}
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    {loading ? <Loader className={'contact-form__button'}/> : <button className="contact-form__button">
                        Change Password
                    </button>}
                </div>
            </form>
        </>
    )
}
