import React, { useState } from 'react'
import { Subject } from '../utils/subjectdata'
import { Citys } from '../utils/stateData'
import MultipleSelect from './multipleSelect'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { webURLs } from '../webServices/GateWay'
import { axiosClient } from '../webServices/WebURL'
import toast from 'react-hot-toast'
import { passwordValidator } from '../utils/helper'
import { Link } from 'react-router-dom'

export default function LearnerRegisterForm() {
    const [allSubject, setAllSubject] = useState(Subject)
    const [city, setCity] = useState(Citys)
    const [visible, setVisible] = useState(false);
    const [pdf, setPdf] = useState()
    const [passwordError, setPasswordError] = useState()
    const [btnDisable, setBtnDisable] = useState(false)
    const [isClear, setIsClear] = useState(true)
    // student user form 
    const [studentForm, setStudentForm] = useState()

    function getStudentDetails(e) {
        const { name, value, files } = e.target;
        setStudentForm({ ...studentForm, [name]: files ? files[0] : value });
    }

    function passValid(e) {
        if (passwordValidator(e.target.value)) {
            getStudentDetails(e)
            setPasswordError('')
        } else {
            setPasswordError("password should be contains atleast One character ,One digit and One Spacial character,minimum password length is 6")
        }
    }

    function getSubject(data) {
        let sub = data?.join("_")
        setStudentForm({ ...studentForm, subject: sub })
    }

    const handleSubmit = async (e) => {

        if (passwordError !== '' || studentForm.password !== studentForm.ConfirmPassword) {
            toast.error("password is not Match")
            return;
        }

        if (pdf) {
            if (pdf[0].type !== "application/pdf") {
                toast.error("Please select Pdf File")
                return;
            }
        }

        toast.loading("Please Wait...")
        setBtnDisable(true)
        e.preventDefault();
        const formData = new FormData();

        // Append basic form data
        formData.append('fullname', studentForm.fullname);
        formData.append('fathername', studentForm.fathername);
        formData.append('mobile', studentForm.mobile);
        formData.append('email_id', studentForm.email_id);
        formData.append('password', studentForm.password);
        formData.append('date_Of_birth', studentForm.date_Of_birth);
        formData.append('gender', studentForm.gender);
        formData.append('identification_type', studentForm.identification_type);
        formData.append('identification_no', studentForm.identification_no);
        formData.append('present_address', studentForm.present_address);
        formData.append('state_name', studentForm?.state_name === "Other" ? studentForm?.other_state_name : studentForm.state_name);
        formData.append('city_name', studentForm?.state_name === "Other" ? studentForm.other_city_name : studentForm?.city_name === "Other" ? studentForm.other_city_name : studentForm?.city_name);
        formData.append('landmark', studentForm.landmark);
        formData.append('pin_code', studentForm.pin_code);
        formData.append('language', studentForm.language);
        formData.append('class_mode', studentForm.class_mode);
        formData.append('Orginization_name', studentForm.Orginization_name);
        formData.append('school_name', studentForm.school_name);
        formData.append('stream', studentForm.stream);
        formData.append('class_name', studentForm.class_name);

        if (pdf) {
            // Append file
            formData.append('pdf', pdf[0]); // Assuming pdf is the name of the file input
        }
        // Append subject or research_project based on the stream
        if (studentForm.stream === "Research") {
            formData.append('area_of_research', studentForm.area_of_research);
            formData.append('research_project', studentForm.research_project);
        } else {
            if (studentForm.subject) {
                formData.append('subject', studentForm.subject);
            } else {
                toast.dismiss();
                toast.error("Please Select Subject")
                return;
            }
        }

        try {
            const response = await axiosClient().post(webURLs.REGISTER_LEARNER, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status) {
                setBtnDisable(false)
                setIsClear(!isClear)
                toast.dismiss()
                toast.success(response.data.message);
                e.target.reset()
            } else {
                setBtnDisable(false)
                toast.dismiss()
                toast.error(response.data.message);
            }
        } catch (error) {
            setBtnDisable(false)
            toast.dismiss()
            toast.error(error.message)
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row mt-4">
                    <div className="col-md-12 mb-3">
                        <h4 className='font-semibold text-center mb-3'>Register As Learner</h4>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor='fullname' className='font-semibold'>Full Name<span className='text-danger'>*</span></label>
                        <input
                            name="fullname"
                            type="text"
                            id='fullname'
                            className="contact-form__input border border-black"
                            onChange={getStudentDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){1,3}$"
                            title="Full name Like : Suraj Kumar"
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Father’s Name<span className='text-danger'>*</span></label>
                        <input
                            name="fathername"
                            type="text"
                            className="contact-form__input border border-black"
                            onChange={getStudentDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,3}$"
                            title="Father name Like : Suraj joshi"
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Mobile Number<span className='text-danger'>*</span></label>
                        <input
                            name="mobile"
                            type="tel"
                            maxLength={10}
                            minLength={10}
                            pattern="[0-9]{10}"
                            className="contact-form__input border border-black"
                            onChange={getStudentDetails}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Email<span className='text-danger'>*</span></label>
                        <input
                            name="email_id"
                            type="email"
                            className="contact-form__input border border-black"
                            onChange={getStudentDetails}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Password<span className='text-danger'>*</span></label>
                        <input
                            name="password"
                            type="password"
                            className="contact-form__input border border-black"
                            required
                            onChange={passValid}
                        />
                        <label htmlFor="password" className='text-red-600'>{passwordError}</label>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Confirm Password<span className='text-danger'>*</span></label>
                        <div className="col-md-12 flex items-center justify-between contact-form__input border border-black">
                            <input
                                name="ConfirmPassword"
                                type={visible ? "text" : "password"}
                                required
                                onChange={getStudentDetails}
                            />
                            <div className='cursor-pointer' onClick={() => setVisible(!visible)}>
                                {visible ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>
                        <label htmlFor="ConfirmPassword" className='text-red-600'>{studentForm?.password === studentForm?.ConfirmPassword ? "" : "password Not match"}</label>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Date of Birth<span className='text-danger'>*</span></label>
                        <input
                            name="date_Of_birth"
                            className="contact-form__input border border-black"
                            type='date'
                            onChange={getStudentDetails}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Gender<span className='text-danger'>*</span></label>
                        <select
                            name="gender"
                            className="contact-form__input border border-black h-9"
                            onChange={getStudentDetails}
                            required
                        >
                            <option value="">-Select Gender-</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Types of Identification<span className='text-danger'>*</span></label>
                        <select
                            name="identification_type"
                            className="contact-form__input border border-black h-9"
                            onChange={getStudentDetails}
                            required
                        >
                            <option value="">-Select Identity Card-</option>
                            <option value="adhaar_card">Adhar Card</option>
                            <option value="pan_card">Pan Card</option>
                            <option value="driving_license">Driving License</option>
                            <option value="passport">Passport</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Identification Number<span className='text-danger'>*</span></label>
                        <input
                            name="identification_no"
                            className="contact-form__input border border-black"
                            type='text'
                            onChange={getStudentDetails}
                            required
                            pattern={studentForm?.identification_type === "adhaar_card" ? "[0-9]{12}" : "[0-9A-Za-z]"}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Present Address<span className='text-danger'>*</span></label>
                        <input
                            name="present_address"
                            className="contact-form__input border border-black"
                            type='text'
                            onChange={getStudentDetails}
                            pattern='[A-Za-z]+(\s[A-Za-z]+){0,4}$'
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>State<span className='text-danger'>*</span></label>
                        <select
                            name='state_name'
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={(e) => setStudentForm({ ...studentForm, [e.target.name]: e.target.value, city_name: "", other_city_name: "", other_state_name: "" })}
                        >
                            <option value="">-Select State-</option>
                            {city && city.map((item, idx) => (
                                <option value={item.state} key={idx}>{item.state}</option>
                            ))}
                        </select>
                    </div>
                    {studentForm?.state_name === "Other" ? <>
                        <div className="col-md-4">
                            <label className='font-semibold'>State Name<span className='text-danger'>*</span></label>
                            <input
                                name='other_state_name'
                                className="contact-form__input border border-black"
                                required
                                onChange={getStudentDetails}
                                pattern='[A-Za-z]+(\s[A-Za-z]+){0,1}$'
                            />
                        </div>
                        <div className="col-md-4">
                            <label className='font-semibold'>City Name<span className='text-danger'>*</span></label>
                            <input
                                name='other_city_name'
                                className="contact-form__input border border-black"
                                required
                                onChange={getStudentDetails}
                                pattern='[A-Za-z]+(\s[A-Za-z]+){0,1}$'
                            />
                        </div>
                    </> : null}
                    {studentForm?.state_name === "Other" ? null : <div className="col-md-4">
                        <label className='font-semibold'>City<span className='text-danger'>*</span></label>
                        <select
                            name='city_name'
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={(e) => setStudentForm({ ...studentForm, [e.target.name]: e.target.value, other_city_name: "" })}
                        >
                            <option value="">-Select City-</option>
                            {city && city.find(item => item.state === studentForm?.state_name)?.city_name?.map((ele, idx) => (
                                <option value={ele} key={idx}>{ele}</option>
                            ))}
                        </select>
                    </div>}
                    {studentForm?.city_name === "Other" ? <div className="col-md-4">
                        <label className='font-semibold'>City Name<span className='text-danger'>*</span></label>
                        <input
                            name='other_city_name'
                            className="contact-form__input border border-black"
                            required
                            onChange={getStudentDetails}
                            pattern='[A-Za-z]+(\s[A-Za-z]+){0,1}$'
                        />
                    </div> : null}
                    <div className="col-md-4">
                        <label className='font-semibold'>Local Landmark<span className='text-danger'>*</span></label>
                        <input
                            name="landmark"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getStudentDetails}
                            pattern='[A-Za-z]+(\s[A-Za-z]+){0,6}$'
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Pin Code<span className='text-danger'>*</span></label>
                        <input
                            name="pin_code"
                            type="tel"
                            className="contact-form__input border border-black"
                            maxLength={6}
                            minLength={6}
                            pattern="[0-9]{6}"
                            required
                            onChange={getStudentDetails}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Preferred Language<span className='text-danger'>*</span></label>
                        <select
                            className="contact-form__input border border-black h-9"
                            required
                            name="language"
                            onChange={getStudentDetails}
                        >
                            <option value="">-Select Preferred Language-</option>
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Preferred Mode<span className='text-danger'></span></label>
                        <select
                            name="class_mode"
                            type="text"
                            className="contact-form__input border border-black h-9"
                            onChange={getStudentDetails}
                            required
                        >
                            <option value="">-Select Preferred Mode-</option>
                            <option value="online">Online</option>
                            <option value="offline">Offline</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Name of Board/University<span className='text-danger'>*</span></label>
                        <input
                            name="Orginization_name"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getStudentDetails}
                            pattern='[A-Za-z]+(\s[A-Za-z]+){0,4}$'
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Name of School/College<span className='text-danger'>*</span></label>
                        <input
                            name="school_name"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getStudentDetails}
                            pattern='[A-Za-z]+(\s[A-Za-z]+){0,4}$'
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Upload School/College ID Card</label>
                        <input
                            name="pdf"
                            className="contact-form__input border border-black"
                            type='file'
                            accept="application/pdf"
                            onChange={(e) => { setPdf(e.target.files) }}
                        />

                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Stream<span className='text-danger'>*</span></label>
                        <select
                            name="stream"
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={(e) => { setStudentForm({ ...studentForm, [e.target.name]: e.target.value, research_project: '', area_of_research: "", subject: "" }); setIsClear(!isClear) }}
                            defaultValue=""
                        >
                            <option value="">-Select Stream-</option>
                            {allSubject && allSubject.map((ele, idx) => (
                                <option value={ele.name} key={idx}>{ele.name}</option>
                            ))}
                        </select>
                    </div>
                    {studentForm?.stream === "Research" ? <div className="col-md-4">
                        <label className='font-semibold'>Research Project<span className='text-danger'>*</span></label>
                        <select
                            name="research_project"
                            className="contact-form__input border border-black h-9"
                            onChange={getStudentDetails}
                        >
                            <option value="">-Select Research Project-</option>
                            {allSubject && allSubject.find(item => item.name === studentForm?.stream)?.subject.map((item, idx) => (
                                <option value={item} key={idx}>{item}</option>
                            ))}
                        </select>
                    </div> : <div className="col-md-4">
                        <label className='font-semibold'>Subject<span className='text-danger'>*</span></label>
                        <MultipleSelect
                            data={allSubject && allSubject.find(item => item.name === studentForm?.stream)?.subject}
                            getData={getSubject}
                            isClear={isClear}
                        />
                    </div>}
                    {studentForm?.stream === "Research" ?
                        <div className="col-md-4">
                            <label className='font-semibold'>Area of Research<span className='text-danger'>*</span></label>
                            <input
                                name='area_of_research'
                                className="contact-form__input border border-black"
                                required
                                onChange={getStudentDetails}
                                pattern='[A-Za-z]+(\s[A-Za-z]+){0,3}$'
                            />
                        </div> : null}
                    <div className="col-md-4">
                        <label className='font-semibold'>Class<span className='text-danger'>*</span></label>
                        <select
                            name="class_name"
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={getStudentDetails}
                        >
                            <option value="">-Select Class-</option>
                            <option value="6th" >6th</option>
                            <option value="7th" >7th</option>
                            <option value="8th" >8th</option>
                            <option value="9th" >9th</option>
                            <option value="10th" >10th</option>
                            <option value="11th" >11th</option>
                            <option value="12th" >12th</option>
                            <option value="diploma">Diploma</option>
                            <option value="UG" >UG</option>
                            <option value="PG" >PG</option>
                            <option value="PhD" >PhD</option>
                        </select>
                    </div>
                    <div className="col-md-12 mt-3">
                        <input type="checkbox" id='checkbox' required />
                        <label htmlFor="checkbox">&nbsp;I agree to the <Link to="/terms-of-use" className='text-[blue]'>Terms of Use</Link></label>
                    </div>
                    <div className="col-md-12 mt-1 mb-3">
                        <div className='d-flex justify-content-center'>
                            <button
                                type="submit"
                                className="btn btn-outline-primary"
                                disabled={btnDisable}
                            >
                               &nbsp; Register &nbsp;
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}