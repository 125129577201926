import React, { useState } from 'react'
import { Subject } from '../utils/subjectdata';
import { Link } from 'react-router-dom';
import { Colors } from '../utils/helper';

export default function CoverSubject() {

    const [allSubject, setAllSubject] = useState(Subject)

    let settings = {
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 4,
    };

    return (
        <>
            {/* ======= Case Studies Area Start ======= */}
            <section className="case">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="section-heading mb-2">
                                <span
                                    className="section-heading__title_small wow fadeInUp text-primary"
                                    data-wow-delay=".3s"
                                    data-wow-duration=".5s"
                                >
                                    SUBJECTS WE COVER
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="section-button mb-2">
                                <Link
                                    to="/subject-we-cover"
                                    className="btn btn--common btn--primary rt-button-animation-out wow fadeInRight"
                                    data-wow-delay=".9s"
                                    data-wow-duration=".7s"
                                >
                                    See More
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="34px"
                                        height="16px"
                                        viewBox="0 0 34.53 16"
                                        xmlSpace="preserve"
                                    >
                                        <rect
                                            className="rt-button-line"
                                            y="7.6"
                                            width={34}
                                            height=".4"
                                        />
                                        <g className="rt-button-cap-fake">
                                            <path
                                                className="rt-button-cap"
                                                d="M25.83.7l.7-.7,8,8-.7.71Zm0,14.6,8-8,.71.71-8,8Z"
                                            />
                                        </g>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className='row'>
                        {allSubject && allSubject.slice(0, 4).map((item, idx) => (
                            <div className="col-sm-6 col-lg-3 col-md-6 p-3" key={idx}>
                                <div
                                    className="case-item wow fadeInUp"
                                    data-wow-delay=".3s"
                                    data-wow-duration=".5s"
                                >
                                    <div className="case-item__image">
                                        <Link to={`/subject/${item.name}`}>
                                            <img
                                                width={"100%"}
                                                src={item.image}
                                                alt="Case Studies"
                                                style={{ height: "280px" }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="case-item__content text-center" style={{background : Colors[idx]}}>
                                        <h5 className="text-white">
                                            <Link to={`/subject/${item.name}`} className='text-capitalize'>{item.name}</Link>
                                        </h5>
                                        {/* <span>App / Website</span> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container-fluid mt-4 mb-5">
                    <div className='row'>
                        {allSubject && allSubject.slice(4, 8).map((item, idx) => (
                            <div className="col-sm-6 col-lg-3 col-md-6 p-3" key={idx}>
                                <div
                                    className="case-item wow fadeInUp"
                                    data-wow-delay=".3s"
                                    data-wow-duration=".5s"
                                >
                                    <div className="case-item__image">
                                        <Link to={`/subject/${item.name}`}>
                                            <img
                                                width={"100%"}
                                                style={{ height: "280px" }}
                                                src={item?.image}
                                                alt="Case Studies"
                                            />
                                        </Link>
                                    </div>
                                    <div className="case-item__content text-center" style={{background : Colors[idx+4]}}>
                                        <h5 className="text-white">
                                            <Link to={`/subject/${item.name}`} className='text-capitalize'>{item.name}</Link>
                                        </h5>
                                        {/* <span>App / Website</span> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* ======= Case Studies Area End ======= */}
        </>
    )
}
