import React, { useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { UpdateAdminApi } from '../webServices/defaultApis';
import { userId } from '../utils/helper';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { addUser } from '../redux/userSlice';
import Loader from './loader';

export default function AdminEditModel({ adminModal, setAdminModal, Data, modelTitle }) {
    const dispatch = useDispatch()
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const id = userId()

    const handleSubmit = async (event) => {
        setIsLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setIsLoading(false)
            setValidated(true);
            return;
        }
        let formDa = new FormData(form);
        const obj = Object.fromEntries(formDa)
        let data = await UpdateAdminApi(id, obj)
        if (data) {
            setIsLoading(false)
            dispatch(addUser(data.data))
            toast.success(data.message)
            setAdminModal(false)
        } else {
            setIsLoading(false)
        }
    };

    return (
        <>
            <Modal show={adminModal} onHide={() => setAdminModal(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Edit ${modelTitle}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} noValidate validated={validated} onChange={() => { setValidated(false); }}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullname"
                                    defaultValue={Data.fullname || " "}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){1,3}$'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Father Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fathername"
                                    defaultValue={Data?.fathername || ''}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){1,3}$'
                                    required
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="mobile"
                                    defaultValue={Data?.mobile || ''}
                                    maxLength={10}
                                    minLength={10}
                                    pattern="[1-9][0-9]{9}"
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email_id"
                                    defaultValue={Data?.email_id || ''}
                                    required
                                />
                            </Form.Group>
                        </Row>
                        <Button variant="primary" type='submit' >
                            {isLoading ? <Loader className={""} /> : "Save Changes"}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAdminModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
