import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "assginEucator",
    initialState: {
        value: []
    },
    reducers: {
        addAssignEducator: (state, action) => {
            state.value = action.payload
        },
        addSingleAssignEducator: (state, action) => {
            state.value = [...state.value, action.payload]
        },
        deleteAssignEducator: (state, action) => {
            let id = action.payload
            state.value = state.value.filter((item) => item.id !== id)
        }
    }
})
export const { addAssignEducator, deleteAssignEducator, addSingleAssignEducator } = slice.actions;
export default slice.reducer;