import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaLocationDot, FaWhatsapp, FaXTwitter, FaYoutube } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            {/* Footer */}
            <footer className="footer footer--bg overflow-hidden">
                <div className="footer--padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 mb-5">
                                <div className="footer-widget">
                                    <div className="footer-widget__title">
                                        <h3 className="footer-widget__title_heading">Contact Us</h3>
                                    </div>
                                    <div className="footer-widget__menu mb-3">
                                        <ul>
                                            <li className="footer-widget__menu_list flex gap-1 text-[#f0f0f0]">
                                                <FaPhoneAlt /><span >+91-9589589318</span>
                                            </li>
                                            <li className="footer-widget__menu_list flex gap-1 text-[#f0f0f0]">
                                                <MdEmail /><span >info@helloeducators.in</span>
                                            </li>
                                            <li className="footer-widget__menu_list flex gap-1 text-[#f0f0f0]">
                                                <FaLocationDot /><span >Indore Madhya Pradesh</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 mb-5">
                                <div className="footer-widget ">
                                    <div className="footer-widget__title">
                                        <h3 className="footer-widget__title_heading">
                                            Main Menu
                                        </h3>
                                    </div>
                                    <div className="footer-widget__menu">
                                        <ul>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/">
                                                    Home
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/about-us">
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/why-hello-educator">
                                                    Why Hello Educator
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/how-it-work">
                                                    How It Works
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/subject-we-cover">
                                                    Subjects We Cover
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/contact-us">
                                                    Contact Us
                                                </Link>
                                            </li>
                                            <li className="footer-widget__menu_list">
                                                <Link className="animation" to="/terms-of-use">
                                                    Terms Of Use
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="footer-widget">
                                    <div className="footer-widget__title">
                                        <h3 className="footer-widget__title_heading">Social Media Links</h3>
                                    </div>
                                    <div className="footer-widget__menu">
                                        <ul className='flex flex-col gap-2'>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://www.facebook.com/profile.php?id=61561243452422&is_tour_dismissed"}
                                                        className="footer-widget__social_list--link facebook"
                                                    >
                                                        <FaFacebookF size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://www.facebook.com/profile.php?id=61561243452422&is_tour_dismissed"} className='text-white'>Facebook</Link>
                                                </div>

                                            </li>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://x.com/EducatorHe28318"}
                                                        className="footer-widget__social_list--link twitter"
                                                    >
                                                        <FaXTwitter size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://x.com/EducatorHe28318"} className='text-white'>X</Link>
                                                </div>
                                            </li>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://www.instagram.com/helloeducator?igsh=M2gzYzFiM3Jjdjlw&utm_source=qr"}
                                                        className="footer-widget__social_list--link instagram"
                                                    >
                                                        <FaInstagram size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://www.instagram.com/helloeducator?igsh=M2gzYzFiM3Jjdjlw&utm_source=qr"} className="text-white">Instagram</Link>
                                                </div>
                                            </li>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://www.linkedin.com/in/hello-educator-276760316/"}
                                                        className="footer-widget__social_list--link linkedin"

                                                    >
                                                        <FaLinkedinIn size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://www.linkedin.com/in/hello-educator-276760316/"} className='text-white'>LinkedIn</Link>
                                                </div>
                                            </li>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://www.youtube.com/channel/UCNMWL9Uqptby5mPWMZA-Jhw"}
                                                        className="footer-widget__social_list--link pinterest"
                                                    >
                                                        <FaYoutube size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://www.youtube.com/channel/UCNMWL9Uqptby5mPWMZA-Jhw"} className='text-white'>YouTube</Link>
                                                </div>
                                            </li>
                                            <li className="footer-widget__social_list">
                                                <div className='flex items-center gap-1'>
                                                    <Link to={"https://wa.me/919589589318"}
                                                        className="footer-widget__social_list--link whatsapp"
                                                    >
                                                        <FaWhatsapp size={16} color='white' />
                                                    </Link>
                                                    <Link to={"https://wa.me/919589589318"} className='text-white'>Whatsapp</Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer--bottom footer--bottom-padding footer--bottom-border bg-black">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="footer__copyright text-center">
                                    <span className="footer__copyright_text">
                                        © 2024 All Rights
                                        Reserved by  <a href="/">Hello Educator</a>
                                    </span>&nbsp;
                                    <span className='text-white'>|</span>&nbsp;
                                    <span className='footer__copyright_text'>
                                        Design And Developed by <a href="https://www.mindcoders.in/" rel="nofollow">
                                            Mindcoders Indore
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer End */}
            {/* /.content-wrapper */}
            <div className="progress-wrap">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </>
    )
}
