import toast from "react-hot-toast";
import { axiosClient } from "./WebURL";
import { webURLs } from "./GateWay";

export async function AdminProfileApi(){
    try {
        const response = await axiosClient().get(webURLs.ADMIN_PROFILE)
        if (response.data.status) {
         return response?.data?.data;
        }
        else {
          toast.error(response.message)
          return ;
        }
    } catch (error) {
        console.log(error)
    }
}

export async function LearnerProfileApi(id){
    try {
        const response = await axiosClient().get(`${webURLs.LEARNER_PROFILE}${id}`)
        if (response.status) {
         return response?.data?.data;
        }
        else {
          toast.error(response.message)
          return ;
        }
    } catch (error) {
        console.log(error)
    }
}

export async function EducatorProfileApi(id){
    try {
        const response = await axiosClient().get(`${webURLs.EDUCATOR_PROFILE}${id}`)
        if (response.status) {
         return response?.data?.data;
        }
        else {
          toast.error(response.message)
          return ;
        }
    } catch (error) {
        console.log(error)
    }
}