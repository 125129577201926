import React from 'react'

export default function Loader({className}) {
    return (
        <div className={`flex justify-center ${className}`}>
            <div class="spinner-border text-white" role="status">
            </div>
        </div>
    )
}
