import React from 'react'
import { GiTeacher } from 'react-icons/gi'
import { PiStudentFill } from 'react-icons/pi'
import { Auth } from "../utils/helper";
import { useSelector } from 'react-redux';

export default function DashContent() {
  const teachersData = useSelector((store) => store.teachers.value)
  const studentData = useSelector((store) => store.students.value)
  const assign = useSelector((store) => store.assign.value)

  const activeTeacher = teachersData && (teachersData.filter(item => item.isAccess) || [])
  const activeStudent = studentData && (studentData.filter(item => item.isAccess) || [])

  return (
    <>
      {Auth() === "admin" && <div className="container ps-7 pt-8">
        <div className='row ps-4 justify-around pt-5'>
          <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex flex-col justify-center">
              <div className="text-2xl flex justify-center items-center gap-2">
                <GiTeacher /><p>Total Enrolled Educator : {teachersData?.length}</p>
              </div>
              <div className="text-2xl flex justify-center items-center gap-2">
                <GiTeacher /><p>Total Verified Educator : {activeTeacher?.length}</p>
              </div>
            </div>
          </div>
          <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex flex-col justify-center">
              <div className="text-2xl flex justify-center items-center gap-2">
                <PiStudentFill /><p>Total Enrolled Learner : {studentData?.length}</p>
              </div>
              <div className="text-2xl flex justify-center items-center gap-2">
                <PiStudentFill /><p>Total Verified Learner : {activeStudent?.length}</p>
              </div>
            </div>
          </div>
          <div className='col-sm-10 col-md-4 col-lg-4 mb-2'>
            <div className="bg-white rounded-2xl border shadow-xl p-2 h-32 items-center flex flex-col justify-center">
              <div className="text-2xl flex justify-center items-center gap-2">
                <GiTeacher /><p>Total Assign Educator : {assign?.length}</p>
              </div>
              <div className="text-2xl flex justify-center items-center gap-2">
                <PiStudentFill /><p>Total Assign Learner : {activeStudent?.length}</p>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}


