import React, { useState } from 'react'
import { Citys } from '../utils/stateData'
import { Subject, class_names } from '../utils/subjectdata'
import MultipleSelect from './multipleSelect'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { axiosClient } from '../webServices/WebURL'
import { webURLs } from '../webServices/GateWay'
import { passwordValidator } from '../utils/helper'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

export default function EducatorRegisterForm() {

    const [allSubject, setAllSubject] = useState(Subject)
    const [city, setCity] = useState(Citys)
    const [classes, setClasses] = useState(class_names)
    const [visible, setVisible] = useState(false);
    const [uploaddegree, setUploaddegree] = useState();
    const [uploadcv, setUploadcv] = useState();
    const [demovideo, setDemovideo] = useState();
    const [passwordError, setPasswordError] = useState()
    const [teacherForm, setTeacherForm] = useState()
    const [btnDisable, setBtnDisable] = useState(false)
    const [isClear, setIsClear] = useState(true)

    function getTeacherDetails(e) {
        const { name, value, files } = e.target
        setTeacherForm({ ...teacherForm, [name]: files ? files[0] : value })
    }
    function getSubject(data) {
        let sub = data?.join("_")
        setTeacherForm({ ...teacherForm, subject: sub })
    }

    function getClasses(data) {
        let cls = data?.join(" ")
        setTeacherForm({ ...teacherForm, class_name: cls })
    }

    function passValid(e) {
        if (passwordValidator(e.target.value)) {
            getTeacherDetails(e)
            setPasswordError('')
        } else {
            setPasswordError("password should be contains atleast One character ,One digit and One Spacial character,minimum password length is 6")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setBtnDisable(true)
        if (passwordError !== '' || teacherForm.password !== teacherForm.ConfirmPassword) {
            toast.error("fill Password Feild")
            return;
        }

        if (uploaddegree) {
            if (uploaddegree[0].type !== "application/pdf") {
                toast.error("please select Pdf file")
                return;
            }
        }

        if (uploadcv) {
            if (uploadcv[0].type !== "application/pdf") {
                toast.error("please select Pdf file")
                return;
            }
        }

        toast.loading("Please Wait...")
        const formData = new FormData();
        formData.append('fullname', teacherForm.fullname);
        formData.append('fathername', teacherForm.fathername);
        formData.append('mobile', teacherForm.mobile);
        formData.append('email_id', teacherForm.email_id);
        formData.append('password', teacherForm.password);
        formData.append('date_Of_birth', teacherForm.date_Of_birth);
        formData.append('gender', teacherForm.gender);
        formData.append('identification_type', teacherForm.identification_type);
        formData.append('identification_no', teacherForm.identification_no);
        formData.append('present_address', teacherForm.present_address);
        formData.append('state_name', teacherForm?.state_name === "Other" ? teacherForm.other_state_name : teacherForm.state_name);
        formData.append('city_name', teacherForm?.state_name === "Other" ? teacherForm.other_city_name : teacherForm.city_name === "Other" ? teacherForm.other_city_name : teacherForm.city_name);
        formData.append('landmark', teacherForm.landmark);
        formData.append('pin_code', teacherForm.pin_code);
        formData.append('language', teacherForm.language);
        formData.append('class_mode', teacherForm.class_mode);
        formData.append('qualification', teacherForm.qualification);
        formData.append('Orginization_name', teacherForm.Orginization_name);
        formData.append('course_name', teacherForm.course_name);
        formData.append('current_designation', teacherForm.current_designation);
        formData.append('experience', teacherForm.experience);
        formData.append('stream', teacherForm.stream)
        formData.append('class_name', teacherForm.class_name);

        if (uploaddegree) {
            formData.append('upload_degree', uploaddegree[0]);
        }
        if (uploadcv) {
            formData.append('upload_cv', uploadcv[0]);
        }

        if (uploaddegree) {
            if (uploaddegree[0].type !== "video/mp4") {
                toast.dismiss()
                toast.error("please select video file")
                return;
            } else {
                formData.append('demo_video', demovideo[0]);
            }
        }

        if (teacherForm.stream === "Research") {
            formData.append('area_of_research', teacherForm.area_of_research);
            formData.append('research_project', teacherForm.research_project);
        } else {
            formData.append('subject', teacherForm.subject);
        }

        try {
            const response = await axiosClient().post(webURLs.REGISTER_EDUCATOR, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data.status) {
                toast.dismiss()
                e.target.reset()
                setDemovideo()
                setUploadcv()
                setUploaddegree()
                setIsClear(!isClear)
                setTeacherForm({})
                setBtnDisable(false)
                toast.success(response.data.message)
            } else {
                toast.dismiss()
                setBtnDisable(false)
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.dismiss()
            setBtnDisable(false)
            toast.error(error.message);
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row mt-4">
                    <div className="col-md-12 mb-3">
                        <h4 className='font-semibold text-center mb-3'>Register As Educator</h4>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Full Name<span className='text-danger'>*</span></label>
                        <input
                            name="fullname"
                            type="text"
                            className="contact-form__input border border-black"
                            onChange={getTeacherDetails}
                            required
                            pattern="[A-Za-z]+(\s[A-Za-z]+){1,3}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Father’s Name<span className='text-danger'>*</span></label>
                        <input
                            name="fathername"
                            type="text"
                            className="contact-form__input border border-black"
                            onChange={getTeacherDetails}
                            required
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,3}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Mobile Number<span className='text-danger'>*</span></label>
                        <input
                            name="mobile"
                            type="tel"
                            maxLength={10}
                            minLength={10}
                            pattern="[1-9][0-9]{9}"
                            className="contact-form__input border border-black"
                            onChange={getTeacherDetails}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Email<span className='text-danger'>*</span></label>
                        <input
                            name="email_id"
                            type="email"
                            className="contact-form__input border border-black"
                            onChange={getTeacherDetails}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Password<span className='text-danger'>*</span></label>
                        <input
                            name="password"
                            type="password"
                            className="contact-form__input border border-black"
                            required
                            onChange={passValid}
                        />
                        <label htmlFor="password" className='text-red-600'>{passwordError}</label>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Confirm Password<span className='text-danger'>*</span></label>
                        <div className="col-md-12 flex items-center justify-between contact-form__input border border-black">
                            <input
                                name="ConfirmPassword"
                                type={visible ? "text" : "password"}
                                required
                                onChange={getTeacherDetails}
                            />
                            <div onClick={() => setVisible(!visible)} className='cursor-pointer'>
                                {visible ? <FaEye /> : <FaEyeSlash />}
                            </div>
                        </div>
                        <label htmlFor="ConfirmPassword" className='text-red-600'>{teacherForm?.password === teacherForm?.ConfirmPassword ? "" : "password Not match"}</label>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Date of Birth<span className='text-danger'>*</span></label>
                        <input
                            name="date_Of_birth"
                            className="contact-form__input border border-black"
                            type='date'
                            onChange={getTeacherDetails}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Gender<span className='text-danger'>*</span></label>
                        <select
                            name="gender"
                            className="contact-form__input border border-black h-9"
                            onChange={getTeacherDetails}
                            required
                        >
                            <option value="">-Select Gender-</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Types of Identification<span className='text-danger'>*</span></label>
                        <select
                            name="identification_type"
                            className="contact-form__input border border-black h-9"
                            onChange={getTeacherDetails}
                            required
                        >
                            <option value="">-Select Identity Card-</option>
                            <option value="adhaar_card">Adhar Card</option>
                            <option value="pan_card">Pan Card</option>
                            <option value="driving_license">Driving License</option>
                            <option value="passport">Passport</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Identification Number<span className='text-danger'>*</span></label>
                        <input
                            name="identification_no"
                            className="contact-form__input border border-black"
                            type='text'
                            onChange={getTeacherDetails}
                            required
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Present Address<span className='text-danger'>*</span></label>
                        <input
                            name="present_address"
                            className="contact-form__input border border-black"
                            type='text'
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>State<span className='text-danger'>*</span></label>
                        <select
                            name='state_name'
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={(e) => setTeacherForm({ ...teacherForm, [e.target.name]: e.target.value, city_name: "", other_city_name: "", other_state_name: "" })}
                        >
                            <option value="">-Select state-</option>
                            {city && city.map((item, idx) => (
                                <option value={item.state} key={idx}>{item.state}</option>
                            ))}
                        </select>
                    </div>
                    {teacherForm?.state_name === "Other" ? <>
                        <div className="col-md-4">
                            <label className='font-semibold'>State Name<span className='text-danger'>*</span></label>
                            <input
                                name='other_state_name'
                                className="contact-form__input border border-black"
                                required
                                onChange={getTeacherDetails}
                                pattern="[A-Za-z]+(\s[A-Za-z]+){0,1}$"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className='font-semibold'>City Name<span className='text-danger'>*</span></label>
                            <input
                                name='other_city_name'
                                className="contact-form__input border border-black"
                                required
                                onChange={getTeacherDetails}
                                pattern="[A-Za-z]+(\s[A-Za-z]+){0,1}$"
                            />
                        </div>
                    </> : null}
                    {teacherForm?.state_name === "Other" ? null :
                        <div className="col-md-4">
                            <label className='font-semibold'>City<span className='text-danger'>*</span></label>
                            <select
                                name='city_name'
                                className="contact-form__input border border-black h-9"
                                required
                                onChange={(e) => setTeacherForm({ ...teacherForm, [e.target.name]: e.target.value, other_city_name: "" })}
                            >
                                <option value="">-Select city-</option>
                                {city && city.find(item => item.state === teacherForm?.state_name)?.city_name?.map((ele, idx) => (
                                    <option value={ele} key={idx}>{ele}</option>
                                ))}
                            </select>
                        </div>}
                    {teacherForm?.city_name === "Other" ? <div className="col-md-4">
                        <label className='font-semibold'>City Name<span className='text-danger'>*</span></label>
                        <input
                            name='other_city_name'
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,1}$"
                        />
                    </div> : null}
                    <div className="col-md-4">
                        <label className='font-semibold'>Local Landmark<span className='text-danger'>*</span></label>
                        <input
                            name="landmark"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Pin Code<span className='text-danger'>*</span></label>
                        <input
                            name="pin_code"
                            type="tel"
                            className="contact-form__input border border-black"
                            maxLength={6}
                            minLength={6}
                            pattern="[0-9]{6}"
                            required
                            onChange={getTeacherDetails}

                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Preferred language<span className='text-danger'>*</span></label>
                        <select
                            className="contact-form__input border border-black h-9"
                            required
                            name="language"
                            onChange={getTeacherDetails}
                        >
                            <option value="">-Select Preferred Language-</option>
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Preferred Mode<span className='text-danger'>*</span></label>
                        <select
                            name="class_mode"
                            type="text"
                            className="contact-form__input border border-black h-9"
                            onChange={getTeacherDetails}
                            required
                        >
                            <option value="">-Select Preferred Mode-</option>
                            <option value="online">Online</option>
                            <option value="offline">Offline</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Educational Qualification<span className='text-danger'>*</span></label>
                        <select
                            className="contact-form__input border border-black h-9"
                            required
                            name="qualification"
                            onChange={getTeacherDetails}
                        >
                            <option value="">-Select Educational Qualification-</option>
                            <option value="diploma">Diploma</option>
                            <option value="ug">UG</option>
                            <option value="pg">PG</option>
                            <option value="phd">PhD</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Name of University/Institute<span className='text-danger'>*</span></label>
                        <input
                            name="Orginization_name"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Name of Course/Specialization<span className='text-danger'>*</span></label>
                        <input
                            name="course_name"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Upload Your Diploma/Degree Certificate</label>
                        <input
                            name="upload_degree"
                            className="contact-form__input border border-black"
                            type='file'
                            accept="application/pdf"
                            onChange={(e) => { setUploaddegree(e.target.files) }}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Current Designation and Employment<span className='text-danger'>*</span></label>
                        <input
                            name="current_designation"
                            type="text"
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[A-Za-z]+(\s[A-Za-z]+){0,3}$"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Experience(In years)<span className='text-danger'>*</span></label>
                        <input
                            name="experience"
                            type="tel"
                            className="contact-form__input border border-black"
                            required
                            onChange={getTeacherDetails}
                            pattern="[0-9]{1,2}"
                        />
                    </div>
                    <div className="col-md-4">
                        <label className='font-semibold'>Upload CV</label>
                        <input
                            name="upload_cv"
                            className="contact-form__input border border-black"
                            type='file'
                            accept="application/pdf"
                            onChange={(e) => { setUploadcv(e.target.files) }}
                        />
                    </div>
                    {teacherForm?.class_mode === "online" && (<div className="col-md-4">
                        <label className='font-semibold'>Upload Demo Video</label>
                        <input
                            name="demo_video"
                            className="contact-form__input border border-black"
                            type='file'
                            accept="video/mp4"
                            onChange={(e) => { setDemovideo(e.target.files) }}
                        />
                    </div>)}
                    <div className="col-md-4">
                        <label className='font-semibold'>Stream<span className='text-danger'>*</span></label>
                        <select
                            name="stream"
                            className="contact-form__input border border-black h-9"
                            required
                            onChange={(e) => { setTeacherForm({ ...teacherForm, [e.target.name]: e.target.value, research_project: '', area_of_research: "", subject: "" }); setIsClear(!isClear) }}
                        >
                            <option value="">-Select Stream-</option>
                            {allSubject && allSubject.map((ele, idx) => (
                                <option value={ele.name} key={idx}>{ele.name}</option>
                            ))}
                        </select>
                    </div>
                    {teacherForm?.stream === "Research" ? <div className="col-md-4">
                        <label className='font-semibold'>Research Project<span className='text-danger'>*</span></label>
                        <select
                            name="research_project"
                            className="contact-form__input border border-black h-9"
                            onChange={getTeacherDetails}
                        >
                            <option value="">-Select Research Project-</option>
                            {allSubject && allSubject.find(item => item.name === teacherForm?.stream)?.subject.map((item, idx) => (
                                <option value={item} key={idx}>{item}</option>
                            ))}
                        </select>
                    </div> : <div className="col-md-4">
                        <label className='font-semibold'>Subject<span className='text-danger'>*</span></label>
                        <MultipleSelect
                            data={allSubject && allSubject.find(item => item.name === teacherForm?.stream)?.subject}
                            getData={getSubject}
                            isClear={isClear}
                        />
                    </div>}

                    {teacherForm?.stream === "Research" ?
                        <div className="col-md-4">
                            <label className='font-semibold'>Area of Research<span className='text-danger'>*</span></label>
                            <input
                                name='area_of_research'
                                className="contact-form__input border border-black"
                                required
                                onChange={getTeacherDetails}
                                pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                            />
                        </div> : null}
                    <div className="col-md-4">
                        <label className='font-semibold'>class<span className='text-danger'>*</span></label>
                        <MultipleSelect
                            data={classes}
                            getData={getClasses}
                            isClear={isClear}
                        />
                    </div>
                    <div className="col-md-12 mt-2">
                        <input type="checkbox" id='checkbox1' required />
                        <label htmlFor="checkbox">&nbsp;I agree to the <Link to="/terms-of-use" className='text-[blue]'>Terms of Use</Link></label>
                    </div>
                    <div className="col-md-12 mt-1 mb-3">
                        <div className='d-flex justify-content-center'>
                            <button
                                type="submit"
                                className="btn btn-outline-primary"
                                disabled={btnDisable}
                            >
                              &nbsp; Register &nbsp;
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
