export const Citys = [
    {
        state: "Madhya Pradesh",
        city_name: ["Bhopal", "Gwalior", "Indore", "Jabalpur", "Rewa",
            "Sagar", "Ujjain", "Other"]
    },
    {
        state: "Uttar Pradesh",
        city_name: ["Aligarh", "Gorakhpur", "Greater Noida", "Lucknow",
            "Kanpur", "Prayagraj", "Varanasi", "Other"]
    },
    {
        state: "Rajasthan",
        city_name: ["Ajmer", "Bikaner", "Jaipur", "Jodhpur", "Kota",
            "Udaipur", "Other"]
    },
    {
        state: "Maharashtra",
        city_name: ["Amravati", "Kolhapur", "Mumbai", "Nagpur", "Pune",
            "Sangli", "Other"]
    },
    {
        state: "Chhattisgarh",
        city_name: ["Bhilai", "Bilaspur", "Durg", "Jagdapur", "Raigarh",
            "Raipur", "Other"]
    },
    {
        state: "Gujarat",
        city_name: ["Ahmedabad", "Gandhinagar", "Rajkot", "Surat", "Vadodara", "Other"]
    },
    {
        state: "Other",
        city_name: ["Other"]
    }
]