import React, { useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import MultipleSelect from './multipleSelect';
import { class_names, Subject } from '../utils/subjectdata';
import { Auth, Options, userId } from '../utils/helper';
import Loader from './loader';
import { UpdateEducatorApi, UpdateLearnerApi } from '../webServices/defaultApis';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { addUser } from '../redux/userSlice';

export default function EditModel({ showModal, setShowModal, modelTitle, Data}) {
    const dispatch = useDispatch()
    const [formObj, setFormObj] = useState({
        stream: Data?.stream,
        subject: Data?.subject,
        research_project: Data?.research_project,
        class_name: Data?.class_name,
        area_of_research: Data?.area_of_research
    })
    const user = Auth()
    const id = userId()
    const [validated, setValidated] = useState(false);
    const [isClear, setIsClear] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [filterSubject, setSubject] = useState(Subject && Subject.find(item => item.name === Data?.stream)?.subject)


    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (user === "educator" && Data?.role === "educator") {
            let formDa = new FormData(form);
            const obj = Object.fromEntries(formDa)
            let objectForm = { ...formObj, ...obj }
            let res = await UpdateEducatorApi(id, objectForm)
            if (res) {
                setIsLoading(false)
                dispatch(addUser(res.data))
                toast.success(res.message)
                setShowModal(false)
            }
            return;
        }

        if (user === "learner" && Data?.role === "learner") {
            let formDa = new FormData(form);
            const obj = Object.fromEntries(formDa)
            let objectForm = { ...formObj, ...obj }
            let res = await UpdateLearnerApi(id, objectForm)
            if (res) {
                setIsLoading(false)
                dispatch(addUser(res.data))
                toast.success(res.message)
                setShowModal(false)
            }
            return;
        }

    };


    function getClasses(data) {
        let cls = data?.join(" ")
        setFormObj({ ...formObj, class_name: cls })
    }

    function getSubject(data) {
        let sub = data?.join("_")
        setFormObj({ ...formObj, subject: sub })
    }

    function getResearchSubject(data) {
        let sub = data?.join("_")
        setFormObj({ ...formObj, research_project: sub })
    }

    function selectStream(e) {
        setFormObj({ ...formObj, stream: e.target.value, subject: "", research_project: "", area_of_research: "" });
        setSubject(Subject && Subject.find(item => item.name === e.target.value)?.subject)
        setTimeout(() => { setIsClear(!isClear) }, 0)
    }


    return (
        <>
            {/* Edit Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Edit ${modelTitle}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} noValidate validated={validated} onChange={() => { setValidated(false); }}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullname"
                                    defaultValue={Data.fullname || " "}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,3}$'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Father Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fathername"
                                    defaultValue={Data?.fathername || ''}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,3}$'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    defaultValue={Data?.mobile || ''}
                                    maxLength={10}
                                    minLength={10}
                                    pattern="[1-9][0-9]{9}"
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email_id"
                                    defaultValue={Data?.email_id || ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date_Of_birth"
                                    defaultValue={Data?.date_Of_birth || ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example" name='gender' defaultValue={Data?.gender || ''} required>
                                    <option value="">-Select Gender-</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Types of Identification</Form.Label>
                                <Form.Select aria-label="Default select example" name='identification_type' defaultValue={Data?.identification_type || ''} required>
                                    <option value="">-Select Identity Card-</option>
                                    <option value="adhaar_card">Adhar Card</option>
                                    <option value="pan_card">Pan Card</option>
                                    <option value="driving_license">Driving License</option>
                                    <option value="passport">Passport</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Identification Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="identification_no"
                                    defaultValue={Data?.identification_no || ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Present Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="present_address"
                                    defaultValue={Data?.present_address || ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state_name"
                                    defaultValue={Data?.state_name || ''}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,1}$'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>City Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city_name"
                                    defaultValue={Data?.city_name || ''}
                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,1}$'
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Local Landmark</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="landmark"
                                    defaultValue={Data?.landmark || ''}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Pin Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="pin_code"
                                    defaultValue={Data?.pin_code || ''}
                                    required
                                    maxLength={6}
                                    minLength={6}
                                    pattern="[0-9]{6}"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Preferred Language</Form.Label>
                                <Form.Select aria-label="Default select example" name='language' defaultValue={Data?.language || ''} required>
                                    <option value="">-Select Preferred Language-</option>
                                    <option value="hindi">Hindi</option>
                                    <option value="english">English</option>
                                    <option value="both">Both</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Preferred Mode</Form.Label>
                                <Form.Select aria-label="Default select example" name='class_mode' defaultValue={Data?.class_mode || ''} required>
                                    <option value="">-Select Preferred Mode-</option>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                    <option value="both">Both</option>
                                </Form.Select>
                            </Form.Group>
                            {Data?.role === 'educator' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Educational Qualification</Form.Label>
                                <Form.Select aria-label="Default select example" name='qualification' defaultValue={Data?.qualification || ''} required>
                                    <option value="">-Select Educational Qualification-</option>
                                    <option value="diploma">Diploma</option>
                                    <option value="ug">UG</option>
                                    <option value="pg">PG</option>
                                    <option value="phd">PhD</option>
                                </Form.Select>
                            </Form.Group>}
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>{Data?.role === "educator" ? "Name of University/ Institute" : "Name of Board/University"}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Orginization_name"
                                    defaultValue={Data?.Orginization_name || ''}
                                    pattern="[A-Za-z]+(\s[A-Za-z]+){0,6}$"
                                    required
                                />
                            </Form.Group>
                            {Data?.role === 'educator' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Name of Course/Specialization</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="course_name"
                                    defaultValue={Data?.course_name || ''}
                                    pattern="[A-Za-z]+(\s[A-Za-z]+){0,6}$"
                                    required
                                />
                            </Form.Group>}
                            {Data?.role === "learner" && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Name of School/College</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="school_name"
                                    defaultValue={Data?.school_name || ''}
                                    pattern="[A-Za-z]+(\s[A-Za-z]+){0,6}$"
                                    required
                                />
                            </Form.Group>}
                            {Data?.role === "educator" && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Current Designation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="current_designation"
                                    defaultValue={Data?.current_designation || ''}
                                    pattern="[A-Za-z]+(\s[A-Za-z]+){0,4}$"
                                    required
                                />
                            </Form.Group>}
                            {Data?.role === 'educator' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Experience(In years)</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="experience"
                                    defaultValue={Data?.experience || ''}
                                    pattern="[0-9]{1,2}"
                                    required
                                />
                            </Form.Group>}
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Stream</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={selectStream} name='stream' defaultValue={Data?.stream || ''} required>
                                    <option value="">-Select Stream-</option>
                                    {Subject && Subject.map((ele, idx) => (
                                        <option value={ele.name} key={idx}>{ele.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            {formObj?.stream === 'Research' ? <>
                                {Data?.role === "educator" && <Form.Group as={Col} md="6" className="mb-2">
                                    <Form.Label>Research Project</Form.Label>
                                    <MultipleSelect
                                        data={filterSubject}
                                        getData={getResearchSubject}
                                        alreadySelected={Options(formObj?.research_project?.split("_"))}
                                        isClear={isClear}
                                    />
                                </Form.Group>}
                                {Data?.role === "learner" && <Form.Group as={Col} md="6" className="mb-2">
                                    <Form.Label>Research Project</Form.Label>
                                    <Form.Select aria-label="Default select example" name='research_project' defaultValue={formObj.research_project} required>
                                        <option value="">-Select Research Project-</option>
                                        {Subject && Subject.find(item => item.name === formObj?.stream)?.subject.map((item, idx) => (
                                            <option value={item} key={idx}>{item}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>}
                                <Form.Group as={Col} md="6" className="mb-2">
                                    <Form.Label>Area of Research</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="area_of_research"
                                        defaultValue={formObj?.area_of_research || ''}
                                        pattern='[A-Za-z]+(\s[A-Za-z]+){0,6}$'
                                        required
                                    />
                                </Form.Group>
                            </> : null}
                            {formObj?.stream !== 'Research' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Subject Enrolled</Form.Label>
                                <MultipleSelect
                                    data={filterSubject}
                                    getData={getSubject}
                                    alreadySelected={Options(formObj?.subject?.split("_"))}
                                    isClear={isClear}
                                />
                            </Form.Group>}
                            {Data?.role === 'educator' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Class</Form.Label>
                                <MultipleSelect
                                    data={class_names}
                                    getData={getClasses}
                                    alreadySelected={Options(formObj?.class_name?.split(" "))}
                                    isClear={true}
                                />
                            </Form.Group>}
                            {Data?.role === 'learner' && <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label>Class</Form.Label>
                                <Form.Select aria-label="Default select example" name='class_name' defaultValue={Data?.class_name || ''} required>
                                    <option value="">-Select Class-</option>
                                    <option value="6th" >6th</option>
                                    <option value="7th" >7th</option>
                                    <option value="8th" >8th</option>
                                    <option value="9th" >9th</option>
                                    <option value="10th" >10th</option>
                                    <option value="11th" >11th</option>
                                    <option value="12th" >12th</option>
                                    <option value="diploma">Diploma</option>
                                    <option value="UG" >UG</option>
                                    <option value="PG" >PG</option>
                                    <option value="PhD" >PhD</option>
                                </Form.Select>
                            </Form.Group>}
                        </Row>
                        <Button variant="primary" type='submit' >
                            {isLoading ? <Loader className={""} /> : "Save Changes"}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
