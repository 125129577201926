export const Subject = [
    {
        name: "Foundation",
        image: "assets/images/foundation.jpg",
        cls: "6th to 10th",
        subject: ["Mathematics", "Science", "Social Science", "English", "Hindi"]
    },
    {
        name: "Science",
        cls: "11th to 12th",
        image: "assets/images/science.jpg",
        subject: ["Mathematics", "Physics", "Chemistry", "Biology", "Computer Science", "Information Practices"]
    },
    {
        name: "Arts",
        image: "assets/images/arts.jpg",
        cls: "11th to 12th",
        subject: ["Economics", "History", "Geography", "Political-Science", "Psychology", "Sociology"]
    },
    {
        name: "Commerce",
        cls: "11th to 12th",
        image: "assets/images/commerce.jpg",
        subject: ["Accountancy", "Business Studies", "Economics", "Finance"]
    },
    {
        name: "Agriculture",
        cls: "11th to 12th",
        image: "assets/images/agriculture.jpg",
        subject: ["Crop Production", "Horticulture", "Animal Husbandry", "Poultry Farming", "Fishery"]
    },
    {
        name: "Engineering",
        cls: "BE And B.Tech",
        image: "assets/images/Engineering.jpg",
        subject: ["Engineering Mathematics", "Engineering Physics", "Engineering Chemistry",
            "Engineering Graphics", "Engineering Mechanics", "Civil Engineering",
            "Electrical Engineering", "Electronics Engineering", "Mechanical Engineering",
            "Computer Engineering", "Information Technology", "Artificial Intelligence",
            "Machine Learning", "Data Science", "Internet of Things",
            "Robotics", "3-D Printing", "Electric Vehicles"
        ]
    },
    {
        name: "Medical",
        cls: "BHMS And MBBS",
        image: "assets/images/Medical.jpg",
        subject: ["Anatomy", "Physiology", "Forensic Medicine", "Toxicology",
            "Pathology", "Pharmacology", "Community Medicine", "Psychiatry",
            "Dermatology", "Anesthesiology", "Obstetrics", "Gynecology",
            "Ophthalmology", "Orthopedics", "Pediatrics", "Surgery"
        ]
    },
    {
        name: "Research",
        cls: "UG PG Ph.D",
        image: "assets/images/Research.jpg",
        subject: ["Science Project", "UG Research Project", "Master Thesis",
            "PhD Thesis"
        ]
    }
]

export const class_names = ["6th", "7th", "8th", "9th", "10th", "11th", "12th", "Diploma", "UG", "PG", "PhD"]
