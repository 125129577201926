import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Subject } from '../../utils/subjectdata'
import { Colors } from '../../utils/helper'
import HeroSection from '../../componets/heroSection'

export default function EachSubject() {

    const { slug } = useParams()
    const [eachsubject, setEachSubject] = useState()

    useEffect(() => {
        let data = Subject.find((item) => item.name === slug)
        if (data) {
            setEachSubject(data)
        }
    }, [])

    return (
        <>
            <main className="main-wrapper">
                <HeroSection sectionName={slug} />
                <section className="services section-startup-bg mb-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8 mx-auto">
                                <div className="section-heading section-heading--startup text-center">
                                    <span className="section-heading__title_small mt-10">
                                        {eachsubject?.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row overflow-hidden">
                            {eachsubject?.subject?.map((item, idx) => (
                                <div
                                    className="col-lg-3 col-sm-12 col-md-6 wow fadeInUp animated"
                                    data-wow-delay=".2s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.2s",
                                        animationName: "fadeInUp"
                                    }}
                                    key={idx}
                                >

                                    <a href="#">
                                        <div className="col-xs-12 col-md-3 btn waves-effect waves-light">
                                            <div
                                                className="btn btn12 tabInactive waves-effect waves-light"
                                                style={{ backgroundColor: Colors[idx], color: "white" }}
                                            >
                                                <i className="icon-play" /> &nbsp; {item}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
