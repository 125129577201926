import React, { useState } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md'
import HeroSection from '../../componets/heroSection'
import { sendEnquiryApi } from '../../webServices/defaultApis'
import toast from 'react-hot-toast'
import Loader from '../../componets/loader'

export default function ContactPage() {

    const [loading, setLoading] = useState(false)

    async function sendEnquiry(event) {
        setLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        let formDa = new FormData(form);
        const obj = Object.fromEntries(formDa)
        try {
            let res = await sendEnquiryApi(obj)
            if (res) {
                setLoading(false)
                event.target.reset()
                toast.success(res.message)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.log(error.message);
            setLoading(false)
        }
    }

    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"Contact Us"} />

                {/* ======= Why Choose Area Start ======= */}
                <section className="services section-startup-bg mt-6 mb-6">
                    <div className="bg-white text-black p-8 z-50 rounded-lg shadow-lg w-full max-w-4xl">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-7 col-md-7 col-sm-12'>
                                    <form className="space-y-6" onSubmit={sendEnquiry}>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                            <div>
                                                <label className="block mb-1 font-semibold">First Name</label>
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    className="w-full px-4 py-2 border border-black rounded-md"
                                                    placeholder="Enter your first name"
                                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,3}$'
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block mb-1 font-semibold">Last Name</label>
                                                <input
                                                    type="text"
                                                    name="lastname"
                                                    className="w-full px-4 py-2 border border-black rounded-md"
                                                    placeholder="Enter your last name"
                                                    pattern='[A-Za-z]+(\s[A-Za-z]+){0,3}$'
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block mb-1 font-semibold">Mobile</label>
                                                <input
                                                    type="tel"
                                                    name="mobile"
                                                    className="w-full px-4 py-2 border border-black rounded-md"
                                                    placeholder="Enter your mobile no"
                                                    maxLength={10}
                                                    minLength={10}
                                                    pattern="[1-9][0-9]{9}"
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label className="block mb-1 font-semibold">Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="w-full px-4 py-2 border border-black rounded-md "
                                                    placeholder="Enter your email"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-semibold ">Message</label>
                                            <textarea
                                                name="message"
                                                className="w-full px-4 py-2 border border-black rounded-md"
                                                placeholder="Enter your message"
                                                rows="4"
                                            />
                                        </div>
                                        {loading ? <Loader className={"contact-form__button"} /> :
                                            <button
                                                type="submit"
                                                className="w-full sm:w-auto bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
                                            >
                                                Submit
                                            </button>
                                        }
                                    </form>
                                </div>
                                <div className='col-lg-5 col-md-5 col-sm-12'>
                                    <div className="p-3">
                                        <h3 className="text-xl mt-4 mb-2">Mobile</h3>
                                        <div className='flex gap-2 items-baseline'><FaPhoneAlt /><p>+91-9589589318</p></div>
                                        <h3 className="text-xl mb-2">Email</h3>
                                        <div className='flex gap-2 items-baseline'><MdEmail /><p className=' justify-center'>info@helloeducators.in</p></div>
                                        <h3 className="text-xl mb-2">Address</h3>
                                        <div className='flex gap-2 items-baseline'><FaLocationDot /> <p className='items-center'>Indore,<br /> Madhya Pradesh</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {/* ======= Contact Area Start ======= */}
                <section
                    className="contact contact--agency"
                    style={{ backgroundImage: 'url("assets/images/bg/contact-bg.jpg")' }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact__agency">
                                    <span className="title text-white">
                                        We are here to answer your questions 24/7
                                    </span>
                                    <h2 className="heading text-white">NEED A CONSULTATION?</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ======= Contact Area End ======= */}
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
