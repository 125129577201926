import toast from "react-hot-toast";
import { axiosClient } from "./WebURL";
import { webURLs } from "./GateWay";

export async function allLearnersApi() {
  try {
    const response = await axiosClient().get(webURLs.GET_ALL_LEARNERS)
    if (response.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allEducatorsApi() {
  try {
    const response = await axiosClient().get(webURLs.GET_ALL_EDUCATORS)
    if (response.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allDeletedLearnersApi() {
  try {
    const response = await axiosClient().get(webURLs.GET_DELETED_LEARNERS)
    if (response.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allDeletedEducatorsApi() {
  try {
    const response = await axiosClient().get(webURLs.GET_DELETED_EDUCATORS)
    if (response.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allAssignTeacherApi(id) {
  try {
    const response = await axiosClient().get(`${webURLs.ASSIGN_ALL_EDUCATOR}${id}`)
    if (response.data.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allAssignApi() {
  try {
    const response = await axiosClient().post(webURLs.ALL_ASSIGN)
    if (response.data.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function deleteEducatorApi(id) {
  try {
    const response = await axiosClient().put(`${webURLs.DELETE_AND_UNDU_EDUCATOR}${id}`)
    if (response.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function deleteLearnerApi(id) {
  try {
    const response = await axiosClient().put(`${webURLs.DELETE_AND_UNDU_LEARNER}${id}`)
    if (response.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAssignEdu(id) {
  try {
    const response = await axiosClient().put(`${webURLs.DELETE_ASSIGN_EDUCATOR}${id}`)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function UpdateAdminApi(id, data) {
  try {
    const response = await axiosClient().put(`${webURLs.UPDATE_ADMIN}${id}`, data)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function UpdateEducatorApi(id, data) {
  try {
    const response = await axiosClient().put(`${webURLs.UPDATE_EDUCATOR}${id}`, data)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function UpdateLearnerApi(id, data) {
  try {
    const response = await axiosClient().put(`${webURLs.UPDATE_LEARNER}${id}`, data)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function sendEnquiryApi(data) {
  try {
    const response = await axiosClient().post(webURLs.SEND_ENQUIRY, data)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allEnquiriesApi() {
  try {
    const response = await axiosClient().get(webURLs.ALL_ENQUIRY)
    if (response.data.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function deleteEnquiryApi(id) {
  try {
    const response = await axiosClient().delete(`${webURLs.DELETE_ENQUIRY}${id}`)
    if (response.data.status) {
      return response.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export async function allCountApi() {
  try {
    const response = await axiosClient().get(webURLs.ALL_COUNT)
    if (response.data.status) {
      return response.data.data;
    }
    else {
      toast.error(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}