import toast from "react-hot-toast";
import { axiosClient } from "./WebURL";
import { webURLs } from "./GateWay";

export async function learnerPermissionApi(id) {
    try {
        const response = await axiosClient().put(`${webURLs.LEARNER_PERMISSION}${id}`)
        if (response.data.status) {
            return response?.data;
        }
        else {
            toast.error(response.message)
        }
    } catch (error) {
        console.log(error)
    }
}

export async function educatorPermissionApi(id) {
    try {
        const response = await axiosClient().put(`${webURLs.EDUCATOR_PERMISSION}${id}`)
        if (response.data.status) {
            return response?.data;
        }
        else {
            toast.error(response.message)
            return;
        }
    } catch (error) {
        console.log(error)
    }
}