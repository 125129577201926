import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Auth, userId } from '../../utils/helper'
import { uploadAdminProfile, uploadCv, uploadDegree, uploadEducatorProfile, uploadLearnerIdCard, uploadLearnerProfile, uploadVideo } from '../../webServices/uploadApis'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { addUser } from '../../redux/userSlice'
import Loader from '../../componets/loader'

export default function UploadFiles() {
    const id = userId()
    const user = Auth()
    const dispatch = useDispatch()
    const [video, setVideo] = useState()
    const [image, setImage] = useState()
    const [cv, setCv] = useState()
    const [degree, setDegree] = useState()
    const [schoolCard, setSchoolCard] = useState()
    const [errors, setErrors] = useState({
        video: false,
        image: false,
        cv: false,
        degree: false,
        idCard: false
    })
    const [loading, setLoading] = useState({
        cv: false,
        video: false,
        image: false,
        degree: false,
        idCard: false
    })

    async function handleUploadVideo(event) {
        setLoading({ ...loading, video: true })
        event.preventDefault()
        let formDa = new FormData();
        if (video[0].type !== "video/mp4") {
            setErrors({ ...errors, video: true })
            setLoading({ ...loading, video: false })
            return;
        }
        formDa.append("demo_video", video[0])
        let res = await uploadVideo(formDa)
        if (res) {
            toast.success(res.message)
            dispatch(addUser(res.data))
            setVideo()
            setLoading({ ...loading, video: false })
            event.target.reset()
        } else {
            setLoading({ ...loading, video: false })
        }
    }

    async function handleImageUpload(event) {
        setLoading({ ...loading, image: true })
        event.preventDefault()
        let formDa = new FormData();
        if (image[0].type !== "image/jpeg") {
            setErrors({ ...errors, image: true })
            setLoading({ ...loading, image: false })
            return;
        }
        formDa.append("avatar", image[0])

        if (user === "educator") {
            let res = await uploadEducatorProfile(formDa)
            if (res) {
                toast.success(res.message)
                dispatch(addUser(res.data))
                setImage()
                setLoading({ ...loading, image: false })
                event.target.reset()
            } else {
                setLoading({ ...loading, image: false })
            }
        }

        if (user === "learner") {
            let res = await uploadLearnerProfile(formDa)
            if (res) {
                setLoading({ ...loading, image: false })
                toast.success(res.message)
                dispatch(addUser(res.data))
                setImage()
                event.target.reset()
            } else {
                setLoading({ ...loading, image: false })
            }
        }

        if (user === "admin") {
            let res = await uploadAdminProfile(formDa)
            if (res) {
                setLoading({ ...loading, image: false })
                toast.success(res.message)
                dispatch(addUser(res.data))
                setImage()
                event.target.reset()
            } else {
                setLoading({ ...loading, image: false })
            }
        }

    }

    async function handleCvUpload(event) {
        setLoading({ ...loading, cv: true })
        event.preventDefault()
        let formDa = new FormData();
        if (cv[0].type !== "application/pdf") {
            setErrors({ ...errors, cv: true })
            setLoading({ ...loading, cv: false })
            return;
        }
        formDa.append("upload_cv", cv[0])
        let res = await uploadCv(formDa)
        if (res) {
            toast.success(res.message)
            dispatch(addUser(res.data))
            setCv()
            setLoading({ ...loading, cv: false })
            event.target.reset()
        } else {
            setLoading({ ...loading, cv: false })
        }
    }

    async function handleUploadDegree(event) {
        setLoading({ ...loading, degree: true })
        event.preventDefault()
        let formDa = new FormData();
        if (degree[0].type !== "application/pdf") {
            setErrors({ ...errors, degree: true })
            setLoading({ ...loading, degree: false })
            return;
        }
        formDa.append("upload_degree", degree[0])
        let res = await uploadDegree(formDa)
        if (res) {
            toast.success(res.message)
            dispatch(addUser(res.data))
            setDegree()
            setLoading({ ...loading, degree: false })
            event.target.reset()
        } else {
            setLoading({ ...loading, degree: false })
        }
    }

    async function handleUploadIdCard(event) {
        setLoading({ ...loading, idCard: true })
        event.preventDefault()
        let formDa = new FormData();
        if (schoolCard[0].type !== "application/pdf") {
            setErrors({ ...errors, idCard: true })
            setLoading({ ...loading, idCard: false })
            return;
        }
        formDa.append("pdf", schoolCard[0])
        let res = await uploadLearnerIdCard(formDa)
        if (res) {
            toast.success(res.message)
            dispatch(addUser(res.data))
            setSchoolCard()
            setLoading({ ...loading, idCard: false })
            event.target.reset()
        } else {
            setLoading({ ...loading, idCard: false })
        }
    }

    return (
        <>
            {id ? <div className="container-fluid ps-20 pt-24">
                <div className="row justify-center p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-4 ">
                        <h2 className='text-center p-2 font-semibold'>Uploads files</h2>
                        <div className="bg-white p-4 shadow rounded-sm border-b-2 border-[#030b47]">
                            <div className='row justify-center'>
                                {user === "learner" && <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                                    <div className="bg-white p-4 rounded-sm border-2">
                                        <h3>Upload School/College ID Card</h3>
                                        <form onSubmit={handleUploadIdCard}>
                                            <input type="file" name='pdf' onChange={(e) => { setSchoolCard(e.target.files); setErrors({ ...errors, idCard: false }) }} required className='form-control mt-3 mb-3' accept='application/pdf' />
                                            {errors.idCard ? <label htmlFor="pdf" className='text-danger relative mt-0'>please upload only pdf file</label> : ""}
                                            {loading.idCard ? <Loader className={"contact-form__button"} /> : <button className='contact-form__button'>Upload</button>}
                                        </form>
                                    </div>
                                </div>}
                                {user === "educator" && <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                                    <div className="bg-white p-4 rounded-sm border-2">
                                        <h3>Upload Your Resume</h3>
                                        <form onSubmit={handleCvUpload}>
                                            <input type="file" name='upload_cv' onChange={(e) => { setCv(e.target.files); setErrors({ ...errors, cv: false }) }} required className='form-control mt-3 mb-3' accept='application/pdf' />
                                            {errors.cv ? <label htmlFor="demo_video" className='text-danger relative mt-0'>please upload only pdf file</label> : ""}
                                            {loading.cv ? <Loader className={"contact-form__button"} /> : <button className='contact-form__button'>Upload</button>}
                                        </form>
                                    </div>
                                </div>}
                                {user === "educator" && <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                                    <div className="bg-white p-4 rounded-sm border-2">
                                        <h3>Upload Your Diploma/Degree Certificate</h3>
                                        <form onSubmit={handleUploadDegree}>
                                            <input type="file" name='upload_degree' onChange={(e) => { setDegree(e.target.files); setErrors({ ...errors, degree: false }) }} required className='form-control mt-3 mb-3' accept='application/pdf' />
                                            {errors.degree ? <label htmlFor="demo_video" className='text-danger relative mt-0'>please upload only pdf file</label> : ""}
                                            {loading.degree ? <Loader className={"contact-form__button"} /> : <button className='contact-form__button'>Upload</button>}
                                        </form>
                                    </div>
                                </div>}
                                {user === "educator" && <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                                    <div className="bg-white p-4 rounded-sm border-2">
                                        <h3>Upload Demo Video</h3>
                                        <form onSubmit={handleUploadVideo}>
                                            <input type="file" name='demo_video' onChange={(e) => { setVideo(e.target.files); setErrors({ ...errors, video: false }) }} required className='form-control mt-3 mb-3' accept='video/mp4' />
                                            {errors.video ? <label htmlFor="demo_video" className='text-danger relative mt-0'>please upload only video file</label> : ""}
                                            {loading.video ? <Loader className={"contact-form__button"} /> : <button className='contact-form__button'>Upload</button>}
                                        </form>
                                    </div>
                                </div>}
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4 p-2">
                                    <div className="bg-white p-4 rounded-sm border-2">
                                        <h3>Upload Profile Picture</h3>
                                        <form onSubmit={handleImageUpload}>
                                            <input type="file" required name='avatar' onChange={(e) => { setImage(e.target.files); setErrors({ ...errors, image: false }) }} className='form-control mt-3 mb-3' accept='image/jpeg' />
                                            {errors.image ? <label htmlFor="demo_video" className='text-danger relative mt-0'>please upload only image file</label> : ""}
                                            {loading.image ? <Loader className={"contact-form__button"} /> : <button className='contact-form__button'>Upload</button>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/dashboard" />}
        </>
    )
}
