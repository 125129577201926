import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "teachers",
    initialState: {
        value: []
    },
    reducers: {
        addTeacher: (state, action) => {
            let data = action.payload
            if (data) {
                state.value = data;
            }
        },
        delTeacher: (state, action) => {
            let id = action.payload
            state.value = state.value.filter((item) => item.id !== id)
        }
    }
})
export const { addTeacher, delTeacher } = slice.actions;
export default slice.reducer;
