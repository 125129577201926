import React from 'react'

export default function HeroSection({ sectionName }) {
    const url = window.location.origin
    return (
        <>
            {/* ======= Hero inner Start ======= */}
            <section className="hero-inner">
                <div
                    className="hero-inner__bg hero-inner__bg_two"
                    style={{ backgroundImage: `url("${url}/assets/images/home1/about-right-image-1.jpg")` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-breadcrumb">
                                    <h1 className="heading text-capitalize">{sectionName}</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="/">Home</a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active text-capitalize"
                                                aria-current="page"
                                            >
                                                {sectionName}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
