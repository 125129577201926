import React from 'react'

export default function About() {
    return (
        <>
            {/* ======= Why Choose Area Start ======= */}
            <section className="about about--area mt-3 mb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="section-heading mb-2">
                                <span
                                    className="section-heading__title_small wow fadeInUp"
                                    data-wow-delay=".2s"
                                    data-wow-duration=".4s"
                                >
                                    About Us
                                </span>
                            </div>
                            <div className="about-content text-[#050A27] wow fadeInUp">
                                <h3 className='font-semibold text-lg'> Vision:</h3>
                                <p className='text-justify'> Our vision is to become a global leader in education by providing a single platform for
                                    young learners and experienced professional educators.
                                </p>
                                <br />
                                <h3 className='mt-1 font-semibold text-lg'> Mission: </h3>
                                <p className='text-justify'> Our mission is to impart the best-quality teaching and research guidance for young
                                    learners by experienced professional educators.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1 mt-5 text-md-center">
                            <div className="about-content__image about-content__image_right position-relative">

                                <img
                                    width={690}
                                    height={617}
                                    src="assets/images/home1/about-right-image-1.jpg"
                                    alt="About Our RadiusTheme Company"
                                    className="about-img wow fadeInRight"
                                    data-wow-delay="1s"
                                    data-wow-duration="1.2s"
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                                <div className="about-content__experience">
                                    <div className="about-content__experience_title">
                                        <h3>Commited To Excellence</h3>
                                        <span>HELLO EDUCATOR</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ======= Why Choose Area End ======= */}
        </>
    )
}
