import './App.css';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './Routes/PublicRoute';
import HomePage from './pages/PublicPages/homePage';
import AboutPage from './pages/PublicPages/aboutPage';
import ContactPage from './pages/PublicPages/contactPage';
import WhyHelloPage from './pages/PublicPages/whyHelloPage';
import HowItWorkPage from './pages/PublicPages/howItWorkPage';
import SubjectsPage from './pages/PublicPages/subjectsPage';
import LoginPage from './pages/PublicPages/LoginPage';
import Registerpage from './pages/PublicPages/registerpage';
import ScrollToTop from './componets/scrollToTop';
import EachSubject from './pages/PublicPages/eachSubject';
import PrivateRoute from './Routes/privateRoute';
import Teacher from './componets/teacher';
import Student from './componets/student';
import Settings from './componets/settings';
import DashContent from './componets/dashContent';
import AdminProfilePage from './pages/PublicPages/adminProfilePage';
import LearnerProfilePage from './pages/PublicPages/learnerProfilePage';
import EducatorsProfilePage from './pages/PublicPages/educatorsProfilePage';
import Layout from './componets/layout';
import TermsConditions from './pages/PublicPages/termsConditions';
import AdminPasswordChange from './componets/adminPasswordChange';
import EducatorRegisterForm from './componets/educatorRegisterForm';
import LearnerRegisterForm from './componets/learnerRegisterForm';
import EducatorPasswordChange from './pages/privatePages/educatorPasswordChange';
import LeanerPasswordChange from './pages/privatePages/leanerPasswordChange';
import TrashPage from './pages/privatePages/trashPage';
import ForgotPasswordPage from './pages/PublicPages/forgotPasswordPage';
import EnquriesPage from './pages/privatePages/enquriesPage';
import UploadFiles from './pages/privatePages/uploadFiles';
import LearnerCount from './pages/PublicPages/learnerCount';
import EducatorCount from './pages/PublicPages/educatorCount';

function App() {
  
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<PublicRoute />} >
          <Route index element={<HomePage />} />
          <Route path='/about-us' element={<AboutPage />} />
          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/why-hello-educator' element={<WhyHelloPage />} />
          <Route path='/how-it-work' element={<HowItWorkPage />} />
          <Route path='/subject-we-cover' element={<SubjectsPage />} />
          <Route path='/subject/:slug' element={<EachSubject />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route path='/register-educator' element={<Registerpage >
            <EducatorRegisterForm />
          </Registerpage>} />
          <Route path='/register-learner' element={<Registerpage >
            <LearnerRegisterForm />
          </Registerpage>} />
          <Route path='/terms-of-use' element={<TermsConditions />} />
          <Route path='/educator' element={<EducatorCount />} />
          <Route path='/learner' element={<LearnerCount />} />
        </Route>
        <Route path='/dashboard' element={<PrivateRoute />} >
          <Route index element={<DashContent />} />
          <Route path='educator' element={<Layout />} >
            <Route index element={<Teacher />} />
            <Route path=':id' element={<EducatorsProfilePage />} />
          </Route>
          <Route path='profile' element={<AdminProfilePage />} />
          <Route path='enquries' element={<EnquriesPage />} />
          <Route path='learner' element={<Layout />} >
            <Route index element={<Student />} />
            <Route path=':id' element={<LearnerProfilePage />} />
          </Route>
          <Route path='setting' element={<Layout />}>
            <Route index element={<Settings />} />
            <Route path='admin' element={<AdminPasswordChange />} />
            <Route path='educator' element={<EducatorPasswordChange />} />
            <Route path='learner' element={<LeanerPasswordChange />} />
            <Route path='upload' element={<UploadFiles />} />
          </Route>
          <Route path='trash' element={<TrashPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
