import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Dashboard from '../pages/privatePages/dashboard'
import { Auth, Token } from '../utils/helper';
import { addTeacher } from '../redux/teacherSlice';
import { allAssignApi, allEducatorsApi, allLearnersApi } from '../webServices/defaultApis';
import { addStudents } from '../redux/studentSlice';
import { useDispatch } from 'react-redux';
import { addAssignEducator } from '../redux/assignEducatorSlice';

export default function PrivateRoute() {
    const dispatch = useDispatch()
    let access = false;
    let user = Auth()
    let token = Token()

    if (token && user) {
        access = true;
    } else {
        access = false;
    }

    useEffect(() => {
        async function getAll() {
            dispatch(addStudents(await allLearnersApi()))
            dispatch(addTeacher(await allEducatorsApi()))
            dispatch(addAssignEducator(await allAssignApi()))
        }
        getAll()
    }, [])

    return (
        <>{
            access ? <Dashboard>
                <Outlet />
            </Dashboard> : <Navigate to="/login" />
        }
        </>
    )
}
