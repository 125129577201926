import React from 'react'
import Profile from '../../componets/profile'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

export default function AdminProfilePage() {
    const user = useSelector((store) => store.User.value)
    
    return (
        <>
            <Profile Data={user} permission={() => { toast.success("Not Access") }} isEditButtonShow={true} />
        </>
    )
}
