import React, { useCallback, useEffect, useState } from 'react'
import Profile from '../../componets/profile'
import { Navigate, useParams } from 'react-router-dom';
import { EducatorProfileApi } from '../../webServices/profileApis';
import { educatorPermissionApi } from '../../webServices/permissionApis';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { allEducatorsApi } from '../../webServices/defaultApis';
import { addTeacher } from '../../redux/teacherSlice';
import { Auth } from '../../utils/helper';

export default function EducatorsProfilePage() {
    const user = Auth()
    const dispatch = useDispatch()
    const [educatorData, setEducatorData] = useState()
    const [refresh, setRefresh] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const { id } = useParams()

    const fetchProfile = useCallback(async () => {
        let response = await EducatorProfileApi(id)
        setEducatorData(response)
    }, [id])

    const GivenPermission = async (id) => {
        setLoading1(true)
        let response = await educatorPermissionApi(id)
        if (response?.status) {
            setLoading1(false)
            setRefresh(!refresh)
            toast.success(response?.message)
        } else {
            setLoading1(false)
        }
    };

    useEffect(() => {
        async function get() {
            fetchProfile()
            dispatch(addTeacher(await allEducatorsApi()))
        }
        get()
    }, [refresh])

    return (
        <>
            {user === "admin" ? <Profile refresh={setRefresh} Data={educatorData} permission={GivenPermission} loading1={loading1} isEditButtonShow={false} /> : <Navigate to="/dashboard" />}
        </>
    )
}
