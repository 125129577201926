import React, { useEffect, useState } from 'react'
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function MultipleSelect({ data, getData, isClear, alreadySelected }) {
    const [selectedOptions, setSelectedOptions] = useState();
    const [Options, setOptions] = useState([])

    const handleChange = (selected) => {
        setSelectedOptions(selected)
        let arr = []
        selected.forEach((item) => {
            arr.push(item.value)
        })
        getData(arr)
    }

    useEffect(() => {
        function options() {
            let arr = data && data.map((item) => (
                { value: item, label: item }))
            setOptions(arr)
        }
        options()
    }, [data])

    useEffect(() => {
        setSelectedOptions([])
    }, [isClear])

    useEffect(() => {
        setSelectedOptions(alreadySelected && alreadySelected)
    }, [])

    return (
        <Select
            closeMenuOnSelect={false}
            components={makeAnimated()}
            value={selectedOptions}
            isMulti
            required
            options={Options}
            onChange={handleChange}
        />
    );
}
