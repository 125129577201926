import React from 'react'
import HowWork from '../../componets/howWork';
import HeroSection from '../../componets/heroSection';

export default function HowItWorkPage() {
    return (
        <>
            {/* ======= Main Wrapper Start ======= */}
            <main className="main-wrapper">
                {/* ======= Hero inner Start ======= */}
                <HeroSection sectionName={"How It Works"}/>
                <HowWork />
            </main>
            {/* ======= Main Wrapper End ======= */}
        </>
    )
}
